import React from 'react'
import {FaAngleDoubleRight, FaArrowRight, FaPlay} from "react-icons/fa";

function AgentsAbout() {
  return (
    <section className="apbc-about-section" id="mov_sobre">
    <div className="container">
      <div className="row">
        <div className="col-lg-6 col-md-8">
          <div className="apbc-about-left">
            <div className="apbc-img-wrapper wow fadeInLeft">
              <img src="assets/img/sz2.gif" className='pim' alt=""/>
            </div> 
          </div>
        </div>
        <div className="col-lg-6">
          <div className="apbc-about-right">
            <div className="apbc-title-area wow fadeInUp">
              <span className="apbc-subtitle">Sobre Agentes</span>
              <div className="apbc-headline">
                <h3>Vantagens Em Ser Um Agente Movicel</h3>
              </div> 
            </div>
            <div className="apbc-about-inner">
              <div className="row">
                <div className="col-md-6">
                  <div className="apbc-inner-content wow fadeInUp">
                    <div className="apbc-headline">
                      <h6><div className='icr'><FaAngleDoubleRight/></div>Melhore o seu negócio</h6>
                    </div>
                    <div className="apbc-pera-txt">
                      <p>Ser Agente Movicel é abrir o seu próprio negócio quando e onde quiser.</p>
                    </div>
                  </div>  
                </div>
                <div className="col-md-6">
                  <div className="apbc-inner-content wow fadeInUp" data-wow-delay="0.4s">
                    <div className="apbc-headline">
                      <h6><div className='icr'><FaAngleDoubleRight/></div>Conquistas</h6>
                    </div>
                    <div className="apbc-pera-txt">
                      <p>Ser Agente Movicel é conquistar a independência financeira tão sonhada e tão procurada.</p>
                    </div>
                  </div>
                </div>
              </div> 
              <p>Vem ser um Agente Movicel!</p>
            </div>  
          </div>
        </div>
      </div>
    </div>
  </section> 
  )
}

export default AgentsAbout
