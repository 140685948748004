import React from 'react'
import { styled } from 'styled-components'
import { MdMarkEmailUnread, MdHomeWork , MdPhoneInTalk} from "react-icons/md";
import { Link } from 'react-router-dom';
import { BsFacebook, BsGlobe, BsLinkedin, BsYoutube, BsInstagram } from "react-icons/bs";
import { Form } from 'react-bootstrap';
import {FiGlobe} from "react-icons/fi";

const Logo = [
    require('../../Assets/images/logo.png'),
    require('../../Assets/images/logo1.png'),
 ]

function Footer(props) {

    const FooterData = [ 
        {
            title:'Outros links',
            links:[
                {title:'Ofertas empresa', source:'/offers'}, 
                {title:'Seja Agente', source:'/agents'}, 
                {title:'Planos Tkuia', source:'#'}, 
                {title:'Planos Karga', source:'#'}, 
                {title:'Movinet', source:'#'}, 
                {title:'Contatos', source:'/contacts'}, 
                {title:'Painel de Administrador', source:'/dashboard'}, 
            ]
        },
        {
            title:'Movicel',
            links:[
               {title:'Sobre Nós', source:'/about'},
               {title:'Cobertura', source:'#'},
               {title:'Regulamento', source:'#'},
               {title:'Responsablidade social', source:'#'},
               {title:'Termos e condições', source:'/terms_and_conditions'},
               {title:'Nossas lojas', source:'/stores'},
            ]
          },
        {
            title:'Apoio Ao Cliente',
            links:[
                {title:'+244 900 000', icons:<MdPhoneInTalk/>, type:1}, 
                {title:'apoioaocliente@movicel.ao', icon:<MdMarkEmailUnread/>, type:2},  
                {title:'Morro da Luz , Luanda-Angola', icon:<MdHomeWork/>, type:null},  
                {title:'Reclamações & Sugestões', source:"#"},   
            ]
        },
        {
            title:'Redes sociais',
            flex:true,
            links:[
                {sc_icon:<BsFacebook/>, source:'#'},  
                {sc_icon:<BsLinkedin/>, source:'#'},  
                {sc_icon:<BsYoutube/>,  color:true, source:'#'},  
                {sc_icon:<BsInstagram/>, source:'#'},  
            ]
        },
    ]



  return (
     <Container>
        <div className="wrapper">
            <section className='footer-section'>
                {FooterData.map((item)=>(
                    <article>
                         <div className="small-title">{item.title}</div>    
                         <ul className= {item.flex ? 'd-flex' : ''}>
                              {item.links.map((lk)=>(
                                  <li> 
                                     {lk.title ?
                                     <>
                                       {lk.source ? <Link to={lk.source}>
                                        <div className={`icon ${lk.icon ? 'mr-2' : ''} ${lk.color ? 'active' : ''}`}>
                                        {lk.icon ? lk.icon : ''}</div> 
                                        {lk.title ? lk.title : ''}</Link> : 
                                        <div className="d-flex"> 
                                            <div className={`icon ${lk.icon ? 'mr-2' : ''} ${lk.color ? 'active' : ''}`}>
                                                {lk.icon ? lk.icon : ''}
                                            </div>
                                            {lk.title ? lk.title : ''}
                                        </div>
                                        } 
                                     </>
                                      :
                                     <>
                                       {lk.sc_icon ?
                                         <a href={lk.source ? lk.source  : '#'} >
                                             <div className={`icon sc ${lk.sc_icon ? 'mr-2' : ''} ${lk.color ? 'active' : ''}`}>
                                               {lk.sc_icon ? lk.sc_icon : ''}
                                            </div>
                                         </a>
                                        :
                                        <></>
                                        }
                                     </> 
                                     } 
                                 </li>
                              ))}
                        </ul>           
                    </article>
                 )) 
                } 
            </section>  
            <div className="copyright">
                 <div className="space">
                     <div>
                     <Link  to="/" ><img src={props.purple ? Logo[1] : Logo[0]} alt="Movicel"/></Link>
                     </div>
                     <div className="language">
                         <FiGlobe/>
                         <Form>
                            <Form.Select>
                                  <option value="pt">Português</option>
                                  <option value="eng">Inglês</option>
                                  <option value="fr">Francês</option>
                                  <option value="sp">Espanhol</option>
                            </Form.Select>
                         </Form>
                     </div>
                 </div>
                <div className="text">     
                    <p>Levamos diariamente aos nossos clientes; calor, amor, amizade, esperança, interacção, cultura e desenvolvimento sustentável.</p>
                    <p></p>
                </div> 
           </div>
        </div>
     </Container>
  )
}


const Container = styled.div`
   min-height:400px;
   padding:40px 0px;
   background:var(--bluedark);


   .copyright{
      border-top:1px solid #33415C;
      padding-top:20px;

       .space{
            display:flex;
            align-items:center;
            justify-content:space-between; 

            img{
                max-width:150px;
            }

            .language{
                  background:var(--white);
                  border-radius:30px;
                  display:flex;
                  align-items:center;
                  padding:5px 10px;

                  select{
                      border:none;
                      box-shadow:unset !important;
                  }

                  svg{
                    min-width:20px;
                    min-height:20px;
                  }
            }
       }


       .text{
            text-align:center;
            margin:20px auto;
            color:var(--white);
            display:flex;
            align-items:center;
            justify-content:center;

            p{
                margin:5px 0px;
                max-width:700px;
                font-size:15px;
            }
       }
   }

     section{
          display:flex;
          flex-wrap:wrap;
          justify-content:space-between;
          width:100%;
          margin: 10px; 

           .small-title{
                color:var(--white);
           }

           ul{
              padding:0px ;
              margin:20px 0px;
              padding-right:20px;

              li{
                  margin:15px 0px;
                  font-size:16px;
                  color:var(--white);
                  cursor:pointer;

                  &:hover{ 
                    color:var(--red); 
                      svg{
                         fill:var(--red);
                      }
                   }

                    a{
                        font-size:16px;
                        color:var(--white);
                        text-decoration:none;

                        &:hover, &:hover svg{
                              color:var(--red);
                        }
                    }
                    
              }

              .icon{
                 svg{
                    fill:var(--white);
                    min-width:20px;
                    min-height:20px;

                    
                    &:hover{
                        fill:var(--red);
                    }
                 }
              }

              .icon.active{
                 svg{
                    fill:var(--red);
                 }
              }

              .mr-2{
                   margin-right:15px;
              }
           }


     }
`;

export default Footer
