import React from 'react'
import { styled } from 'styled-components';

function AboutTeam() {
 
    const Data = [
        {name:'Carlos André', charge:'CEO',image:''},
        {name:'Pitter George', charge:'CTO',image:''},
        {name:'José Jobes', charge:'Conselheiro',image:''},
        {name:'Marta Pedro', charge:'Diretor de vendas',image:''},
        {name:'Kyle Mendes', charge:'Financeiro',image:''},
        {name:'William Borges', charge:'CEOO',image:''},
        {name:'Shakur Amari', charge:'Diretor de RH',image:''},
        {name:'Silva Paulo', charge:'Secretario Geral',image:''},
        {name:'Abilio Santos', charge:'Conselheiro',image:'Recrutadora'},
        {name:'Sara Brain', charge:'Gerente de sistemas',image:''}
    ]

 return (
    <Container>
       <div className="wrapper"> 
       <div className="apbc-title-area wow fadeInUp"> 
            <div className="apbc-headline">
                <h3>Direção da empresa</h3>
            </div>
          </div> 
         <div className="row">
            {Data.map((item, index)=>{
                 return(
                 <div className="box" key={index}>
                    <div className="image"></div>
                    <div className="details">
                        <h2>{item.name}</h2>
                        <p>{item.charge}</p>
                   </div>
                  </div>
                 )
            })}
         </div>
       </div>
    </Container>
  );
}

const Container = styled.div`
    margin-top:20px;
    width:100%;
    min-height:500px; 
    background:var(--white-smoke);
    display:flex;
    flex-wrap:wrap;
    padding:30px 0px;


   .row{
    justify-content:space-between;
    
      .box{
            width:19%;
            height:300px;
            border:1px solid red;
            min-width:150px;
            padding:0px;
            background:var(--white);
            margin:10px 0px;
            border:1px solid var(--white-smoke);

              .image{
                  height:200px;
                  background:#E7E9EF;
                  width:100%;
              }

              .details{
                  padding:10px;

                    h2{
                        font-size:18px;
                        font-weight:600;
                        margin:8px 0px;
                        color:var(--bluedark);
                    }

                    p{
                        font-size:16px;
                    }
              }
        }
   }

`;

export default AboutTeam;