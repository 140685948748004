import React from 'react'
import { styled } from 'styled-components';
import {MdKeyboardDoubleArrowUp} from "react-icons/md";
import { useState } from 'react';

function ScrollTop() {
const [FixedMenu, setFixedMenu] = useState(false);

const changeNavbarColor = () => {
    if (window.scrollY >= 400) {
        setFixedMenu(true);
    }
    else {
        setFixedMenu(false); 
    }
};
window.addEventListener('scroll', changeNavbarColor);

  return (
     <a href="#"  className="apbc-scroll-top"> 
        <MdKeyboardDoubleArrowUp/>  
     </a>
  )
}

const Container = styled.div`
    display:flex;
    align-items:center;
    position:fixed;  
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
    color: #ffffff !important; 
    font-size: 24px;
    text-align: center;
    line-height: 50px;
    display: inline-block; 
    bottom: 30px;
    right: 30px;
    z-index: 10;
    border-radius: 4px; 
    -webkit-transition: initial;
    -o-transition: initial;
    transition: initial;
    cursor:pointer;

       svg{
           min-width:30px;
           min-height:30px;
           fill:var(--white);
           margin:0px;
       }
`;

export default ScrollTop
