import React from 'react'
import { styled } from 'styled-components'; 
import { BiPlanet,BiRocket,BiSend  } from "react-icons/bi";

import {BsCheck} from "react-icons/bs";
import { Link } from 'react-router-dom';
function CompaniesCardSection() {
     
    const Data = [
      {
        title:'Base', 
         icon:<BiPlanet/>,
         price:'3.000Kz', 
         link:"/kargas",
         small_description:'plano basico para clientes',
         type:'plano mensal',
         features:[
          '20mn para falar das 06-19h',
          '123 mensagens das 14-19H',
          '134GB de dados moveis',
          '500 minutos de voz',
          '30 mensasgens das 00-12H',
         ],
      }, 
      {
          
          title:'Internacional', 
           price:'5.000Kz',
           link:"/movinet", 
           small_description:'plano internacional para clientes',
           type:'plano mensal',  
           active:true,
            icon:<BiRocket/>,
           features:[
              '20mn para falar das 06-19h',
              '123 mensagens das 14-19H',
              '134GB de dados moveis',
              '500 minutos de voz',
              '30 mensasgens das 00-12H',
           ],
        },
        {
          title:'Outro', 
          icon:<BiSend/>,
           price:'10.000Kz', 
           link:"/spyka",
           small_description:'outros planos para clientes',
           type:'plano mensal',
           features:[
              '20mn para falar das 06-19h',
              '123 mensagens das 14-19H',
              '134GB de dados moveis',
              '500 minutos de voz',
              '30 mensasgens das 00-12H',
           ],
        },
  ];

  return ( 
     <Container>
     {Data.map((item, index)=>{
         return(
           <div className={item.active ? "card-box active animate__animated animate__fadeInRight" 
           : "card-box animate__animated animate__fadeInRight"} key={index}>
                   <div className="space">
                       <div> </div>
                       {item.active ? <div className='pl'><BsCheck/></div> : <></>}
                   </div>
                   <div className="card-header">
                       <div className="icon">{item.icon}</div>
                       <h4>{item.title}</h4>
                   </div> 
                <div className="sm"> <span>{item.small_description}</span></div>
                <Link to="#"><button className="btn">Saiba mais</button></Link>
               <div className="text-left">
               <h3>Recursos</h3> 
                <ul>
                    {item.features.map((ft, i)=>{
                       return(
                          <li key={i}><div className="check"><BsCheck/></div>{ft}</li>
                       )
                    })
                    }
                </ul>
               </div>
            </div>
         )
     })
         
     }
</Container>
  )
}


const Container = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    flex-wrap:wrap;
    margin:30px 0px;


    .card-box{
        min-width:360px;
        width:360px;
        min-height:450px;
        background:#ffff; 
        margin:10px;
        -webkit-box-shadow: -3.119px 14.672px 18px 0px rgba(90, 90, 90, 0.15);
        box-shadow: -3.119px 14.672px 18px 0px rgba(90, 90, 90, 0.15); 
        border-radius:4px;
        padding:20px;
        color:var(--bluedark);


        .card-header{
          display: flex;
          align-items: center;
          justify-content: center; 
          flex-direction:column;
        }

        .text-left{
            text-align:left;
        }

        .icon{
          width:100px;
          height:100px; 
         display: flex;
         align-items: center;
         justify-content: center; 
         border-radius:100%;
         background:var(--purple);

           svg{
               width:50px;
               height:50px;
               fill:var(--white);
           }
       }

        .space{
              width:100%;
              display:flex;
              align-items:center;
              margin-bottom:15px;
              justify-content:space-between;

              h2{
                  font-size:18px;
                  margin:0px;
                  font-weight:bolder;
                  color:var(--bluedark);
              }

              .pl{
                  padding:4px;
                  border-radius:30px;
                  font-size:10px;
                  font-weight:bolder; 

                   svg{
                      width:22px;
                      height:22px;
                   }
              }
        }

        .check, .pl{
            background:var(--purple) !important;
        }

        button{
              background:var(--white);
              border:2px solid var(--purple);
              color: var(--purple);
              width:100%;
              margin:10px 0px; 
        }

        .d-flex{
            align-items:center;
            margin-bottom:20px; 

            small{
                margin:0px;
            }
        }

        h4{
          margin-right:10px;
          font-weight:bolder;
          font-size:25px;
          color:var(--bluedark);
          margin-top:10px;
      }

        small, span, li{
            font-size:14px;
            color:var(--bluedark);
        }

        h3{
            color:var(--bluedark);
            font-weight:bolder;
            font-size:18px;
            margin-top:20px; 
            text-transform: uppercase;
        }

        ul{
            padding:0px;
            margin:0px;
            padding-top:10px;

             li{
                  display:flex;
                  align-items:center;
                  margin:10px 0px;

                .check{
                    margin-right:10px;
                    width:15px;
                    height:15px;
                    border-radius:100%;
                    display:flex;
                    align-items:center;
                    justify-content:center;

                     svg{
                          min-width:15px;
                          min-height:15px;
                          fill:#ffff;
                     }
                  }
             }
        }




         
        &:hover{ 
            background:var(--purple) !important;
            color:#ffff;
   
            h2,h4,h3{
               color:#ffff;
            }
   
            small, span, li{
               color:#ffff;
           }
   
            button{
               background:var(--bluedark);
               color:#ffff;
            }

            .check, .pl, .icon{
                background:var(--bluedark) !important;
            }
        }


    
    }

    .card-box.active{
        background:var(--purple);
        color:#ffff;

        h2,h4,h3{
           color:#ffff;
        }

        small, span, li{
           color:#ffff;
       }

        button{
           background:#ffff;
           color:var(--bluedark);
        }

        .check, .pl, .icon{
            background:var(--bluedark) !important;
        }
        
        &:hover{
            background:var(--bluedark) !important;
            color:#ffff;

            h2,h4,h3{
                color:#ffff;
            }

            small, span, li{
                color:#ffff;
            }

            .check, .pl, .icon{
                background:var(--purple) !important;
            }

            button{
                background:#ffff;
                color:var(--bluedark);
            }
        }
    }
`;
 


export default CompaniesCardSection
