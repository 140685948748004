import React from 'react'
import Slider from 'react-slick';
import { styled } from 'styled-components';
import {FaAngleDoubleRight, FaArrowRight, FaPlay} from "react-icons/fa";
import SocialLinks from './SocialLinks';
import AboutButton from './AboutButton';

const  Images = [ 
    require("../../Assets/img/business-4/slider/slider-1.png"), 
]; 

function CompanyHeader() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        margin:0,
        slidesToScroll:1
      };
    
      return (
        <Container className='purple'>  
            <Slider  {...settings} className="apbc-hero-slider" id="apbc_banner">
                   <div className="apbc-single-item s1" style={{backgroundImage:`url(${Images[0]})`}}>
                     <span className="apbc-slide-shape-1"><img src={Images[0]} alt=""/></span>
                     <span className="apbc-slide-shape-2"><img src={Images[0]} alt=""/></span> 
                     <div className="container">
                       <div className="row">
                         <div className="col-lg-7">
                           <div className="apbc-slide-left">
                             <div className="apbc-headline">
                               <h1>O sucesso do seu Negócio; passa pela Movicel.</h1>
                             </div>
                             <div className="apbc-pera-txt">
                               <p> Os Planos Empresa Movicel oferecem : inovação, dinamismo, versatilidade, comunicação ilimitada, economia de tempo e vantagens competitivas para o seu negócio.</p>
                             </div>
                              <AboutButton />
                           </div>
                         </div>
                       </div>
                     </div>
                   </div>  
            </Slider> 
        </Container>
      )
    }
    
    const Container = styled.div` 
    position:relative;
    
    .apbc-single-item{  
        background-repeat:no-repeat;
        background-position: right;
        min-height:908px;
        max-height:908px;
      }
    
      .apbc-single-item.s1{
        background-image:url('assets/img/business-4/slider/slider-1.png');
      }
       
    
    
       .apbc-primary-btn .icon{
            width:40px;
            min-width:40px;
            height:40px;
            border-radius:100%;
            margin-left:10px !important;
            display: flex;
            align-items: center;
            justify-content: center;
            background:var(--white);
    
            svg{
                fill:var(--red);
            }
       }
    `;

export default CompanyHeader
