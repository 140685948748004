import React from 'react'

function OffersCards() {
  return (
    <div>
         <section className="apbc-team-section" id="mov_ofertas">
             <div className="container">
               <div className="row">
                 <div className="col-lg-8 offset-lg-2">
                   <div className="apbc-title-area text-center wow fadeInUp">
                     <span className="apbc-subtitle">Ofertas</span>
                     <div className="apbc-headline">
                       <h3>Aproveite as nossas ofertas nesse verão</h3>
                     </div>
                   </div>
                 </div>
               </div>
               <div className="apbc-team-content">
                 <div className="apbc-team-carousel">
                  
                   <div className="apbc-team-single">
                     <div className="apbc-img-wrapper"> 
                       <img src="assets/img/sz5.png" className='pim' alt=""/>
                     </div> 
                     <div className="apbc-social-icons">
                       <a href="#"><i className="fab fa-facebook"></i></a>
                       <a href="#"><i className="fab fa-twitter"></i></a>
                       <a href="#"><i className="fab fa-instagram"></i></a>
                     </div> 
                     <div className="apbc-member-content">
                       <div className="apbc-headline">
                         <a href="#"><h6>Plano Karga</h6></a>
                       </div>
                       <div className="apbc-designation">
                         <span>Apartir de Kz 2.0000</span>
                       </div>
                     </div>
                   </div>
     
                   <div className="apbc-team-single">
                     <div className="apbc-img-wrapper"> 
                       <img src="assets/img/sz5.png" className='pim' alt=""/>
                     </div>
                   
                     <div className="apbc-member-content">
                       <div className="apbc-headline">
                         <a href="#"><h6>Plano Movinet</h6></a>
                       </div>
                       <div className="apbc-designation">
                         <span>Kz 32.000/mês + Router Grátis</span>
                       </div>
                     </div>
                   </div>
     
                   <div className="apbc-team-single">
                     <div className="apbc-img-wrapper"> 
                       <img src="assets/img/sz5.png" className='pim' alt=""/>
                     </div>
                   
                     <div className="apbc-member-content">
                       <div className="apbc-headline">
                         <a href="#"><h6>Plano Karga</h6></a>
                       </div>
                       <div className="apbc-designation">
                         <span>Kz 2.000/30 dias</span>
                       </div>
                     </div>
                   </div>

                   <div className="apbc-team-single">
                     <div className="apbc-img-wrapper"> 
                       <img src="assets/img/sz5.png" className='pim' alt=""/>
                     </div>
                     
                     <div className="apbc-member-content">
                       <div className="apbc-headline">
                         <a href="#"><h6>Plano Movinet</h6></a>
                       </div>
                       <div className="apbc-designation">
                         <span>Kz 32.000/mês</span>
                       </div>
                     </div>
                   </div>

                 </div>
               </div>
             </div>
           </section> 
    </div>
  )
}

export default OffersCards