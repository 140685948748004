import React from 'react'
import { Link } from 'react-router-dom';
import { styled } from 'styled-components';
import Footer from './Pages/Components/Footer';
import ScrollTop from './Pages/Components/ScrollTop';
import MvNavbar from './Pages/Components/MvNavbar';
import {FaAngleDoubleRight, FaArrowRight, FaPlay} from "react-icons/fa";
import HomeHeader from './Pages/Components/HomeHeader';
import ServicesSlider from './Pages/Components/ServicesSlider';
import TestimonialsSlider from './Pages/Components/TestimonialsSlider';
import OffersSection from './Pages/Components/OffersSection';
import PlayerModal from './Pages/Components/PlayerModal';
import AgentRequirementsSection from './Pages/Components/AgentRequirementsSection';
import OffersCards from './Pages/Components/OffersCards';

  
function Intro() {
  document.title = "Movicel";
    return (
        <>
         <Container> 
             <ScrollTop/>
             <MvNavbar active={1}  />
            <HomeHeader />  
           <ServicesSlider /> 
           <section className="apbc-about-section" id="mov_sobre">
             <div className="container">
               <div className="row">
                 <div className="col-lg-6 col-md-8">
                   <div className="apbc-about-left">
                     <div className="apbc-img-wrapper wow fadeInLeft">
                       <img src="assets/img/sz2.gif" className='pim' alt=""/>
                     </div>
                     <div className="apbc-countarea wow fadeInUp">
                       <div className="apbc-headline">
                         <h2><span className="apbc-counterup">18</span><sup>+</sup></h2>
                       </div>
                       <div className="apbc-exp">
                         <span>ANOS DE EXPERIÊNCIA<br/>Acabamos de conquistar</span>
                       </div>
                     </div>
                   </div>
                 </div>
                 <div className="col-lg-6">
                   <div className="apbc-about-right">
                     <div className="apbc-title-area wow fadeInUp">
                       <span className="apbc-subtitle">Sobre Nós</span>
                       <div className="apbc-headline">
                         <h3>Movicel, “A Operadora de Todos os Angolanos” Conectamos angola de Cabinda ao Cunene e do Mar ao Leste</h3>
                       </div>
                       <div className="apbc-pera-txt">
                         <p>Levamos diariamente aos nossos clientes; calor, amor, amizade, esperança, interacção, cultura e desenvolvimento sustentável.</p>
                       </div>
                     </div>
                     <div className="apbc-about-inner">
                       <div className="row">
                         <div className="col-md-6">
                           <div className="apbc-inner-content wow fadeInUp">
                             <div className="apbc-headline">
                               <h6><div className='icr'><FaAngleDoubleRight/></div>Missão</h6>
                             </div>
                             <div className="apbc-pera-txt">
                               <p>Fornecer serviços de comunicação
                                de classe mundial aos nossos clientes, agregando valor através de soluções de conectividade confiáveis e acessíveis </p>
                             </div>
                           </div>
                           <div className="apbc-inner-content wow fadeInUp" data-wow-delay="0.2s">
                             <div className="apbc-headline">
                               <h6><div className='icr'><FaAngleDoubleRight/></div>Visão</h6>
                             </div>
                             <div className="apbc-pera-txt">
                               <p>Ser o provedor de serviços de comunicações preferencial em Angola</p>
                             </div>
                           </div>
                         </div>
                         <div className="col-md-6">
                           <div className="apbc-inner-content wow fadeInUp" data-wow-delay="0.4s">
                             <div className="apbc-headline">
                               <h6><div className='icr'><FaAngleDoubleRight/></div>Valores</h6>
                             </div>
                             <div className="apbc-pera-txt">
                                 <p>
                                   <div className="wrap-box">
                                      Qualidade <div className="dot"></div>
                                      Foco no cliente <div className="dot"></div>
                                      Acessível <div className="dot"></div>
                                      Confiável  <div className="dot"></div>
                                      Inovador  <div className="dot"></div>
                                      Integridade <div className="dot"></div>
                                      Paixão
                                   </div>
                                </p>
                             </div>
                           </div>
                         </div>
                       </div>
                     </div> 
                     <div className="apbc-primary-btn wow fadeInUp" data-wow-delay="0.5s">
                       <a href="#" className='bg-primary'>Saiba mais <div className='ml-2'><div className='icon'><FaArrowRight/></div> </div></a>
                     </div> 
                   </div>
                 </div>
               </div>
             </div>
           </section>    
           <OffersSection/>  
           <br /><br />
           <section className="apbc-video-section"  >
             <div className="container">
               <div className="row align-items-center">
                 <div className="col-lg-6 order-lg-1 order-2">
                   <div className="apbc-video-left">
                     <div className="apbc-title-area wow fadeInUp">
                       <span className="apbc-subtitle">Momento Medicare</span>
                       <div className="apbc-headline">
                         <h3>Dá karga à Tua Saúde</h3>
                       </div>
                     </div>
                     <div className="apbc-left-middle wow fadeInUp"> 
                       <div className="apbc-primary-btn">
                         <a href="#">Get In touch <div className='ml-2'><div className='icon'><FaArrowRight/></div> </div></a>
                       </div> 
                       <div className="apbc-phone-number">
                         <span><i className="fas fa-phone-alt"></i> com Anselmo Ralph</span>
                       </div>
                     </div>
                   </div>
                 </div>
                 <div className="col-lg-6 order-lg-2 order-1"> 
                   <PlayerModal toggle_btn={
                      <div className="apbc-video-right"> 
                          <a><FaPlay/></a>
                      </div>
                   } /> 
                 </div>
               </div>
               <div className="apbc-video-content">
                 <div className="row">
                   <div className="col-lg-3 col-md-4 col-sm-6">
                     <div className="apbc-video-column wow fadeInUp">
                       <div className="apbc-icon-wrapper">
                         <i className="flaticon-team"></i>
                       </div>
                       <div className="apbc-column-content">
                         <div className="apbc-headline">
                           <h4 className="apbc-counterup">10.000</h4><span>+</span>
                         </div>
                         <div className="apbc-tagline">
                           <span>Clientes Satisfeitos</span>
                         </div>
                       </div>
                     </div>
                   </div>
                   <div className="col-lg-3 col-md-4 col-sm-6">
                     <div className="apbc-video-column wow fadeInUp" data-wow-delay="0.2s">
                       <div className="apbc-icon-wrapper">
                         <i className="flaticon-project-management"></i>
                       </div>
                       <div className="apbc-column-content">
                         <div className="apbc-headline">
                           <h4 className="apbc-counterup">5.000</h4><span>+</span>
                         </div>
                         <div className="apbc-tagline">
                           <span>Planos de Saúde</span>
                         </div>
                       </div>
                     </div>
                   </div>
                   <div className="col-lg-3 col-md-4 col-sm-6">
                     <div className="apbc-video-column wow fadeInUp" data-wow-delay="0.4s">
                       <div className="apbc-icon-wrapper">
                         <i className="flaticon-idea"></i>
                       </div>
                       <div className="apbc-column-content">
                         <div className="apbc-headline">
                           <h4 className="apbc-counterup">30</h4><span>+</span>
                         </div>
                         <div className="apbc-tagline">
                           <span>Soluções</span>
                         </div>
                       </div>
                     </div>
                   </div>
                   <div className="col-lg-3 col-md-4 col-sm-6">
                     <div className="apbc-video-column wow fadeInUp" data-wow-delay="0.6s">
                       <div className="apbc-icon-wrapper">
                         <i className="flaticon-link"></i>
                       </div>
                       <div className="apbc-column-content">
                         <div className="apbc-headline">
                           <h4 className="apbc-counterup">40</h4><span>+</span>
                         </div>
                         <div className="apbc-tagline">
                           <span>Parceiros</span>
                         </div>
                       </div>
                     </div>
                   </div>
                 </div>
               </div>
             </div>
           </section>    
             <OffersCards />
           <br />
          <Footer/>
       </Container>
    </>
  );
}


const Container = styled.div`
  
.apbc-video-section{
    background-image:url('assets/img/business-4/slider/banner.png');
    background-repeat:no-repeat;
    background-position: right;
}


.apbc-about-section .apbc-img-wrapper{
  min-height:600px;  
  background:#E7E9EF;

    img{
       width:100%;
    }
}


.apbc-team-single .apbc-img-wrapper{
  background:#E7E9EF;
  width:100%;  
  min-height:300px; 
}

.apbc-left-middle{
   display:block;
}
  
`;

export default Intro
