import React from 'react'
import { styled } from 'styled-components'
import CompanyHeader from './Components/CompanyHeader';
import MvNavbar from './Components/MvNavbar';
import Footer from './Components/Footer';
import CompaniesCardSection from './Components/CompaniesCardSection';
import CompaniesBanner from './CompaniesBanner';
import CompanyMontlyPlans from './Components/CompanyMontlyPlans';
import CompanyAdditions from './Components/CompanyAdditions';
import { Link } from 'react-router-dom';
import ScrollTop from './Components/ScrollTop';

function Companies() {
    document.title = "Empresas"
  return (
      <Container className='purple-area'>
           <MvNavbar purple active={5} />
             <CompanyHeader />
               <div className="wrapper">
                 <div className="cards-container">
                   <div className="apbc-title-area text-center wow fadeInUp"> 
                     <div className="apbc-headline">
                       <h3>Tarifarios</h3>
                     </div>
                   </div>
                    <p className="description">Lorem ipsum dolor sit amet, consecadipiscing elit, sed do eiusmod tempor incididunt ut ore et dolore magna aliqua. Quis ipsum suspendisse gravida. Risus commodo viverra maecenasan lacus vel facilisis.</p>
                    <CompaniesCardSection/>
                 </div>   
                 <CompaniesBanner />
               </div> 
               <CompanyMontlyPlans />
               <br />
               <div className="wrapper">
                   <CompanyAdditions />
               </div>
               <br />
               <div className="experience bg-primary">
                 <div className="wrapper">
                    <div className="space">
                      <h1 className='mediumn-title'>Os melhores serviços de telecomunicação só na Movicel </h1>
                    <div>
                    <Link to='/contacts'> <button className="btn btn-circle bg-light">Fale connosco !</button></Link>
                    </div>
                </div>
            </div>
         </div>
         <Footer purple />
         <ScrollTop/>
      </Container>
  )
}

const Container = styled.div`
   width:100%;

   .apbc-scroll-top{
      background:var(--purple) !important;
   }

  .experience .space{
    display:flex;
    align-items:center;
    justify-content:space-between;
    padding:20px;


      .mediumn-title{ 
           color:var(--white);
       }

       button{
          padding:10px 20px;
          color:var(--purple);
       }
   }

   .cards-container{
       text-align:center;
       padding:30px 0px; 
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column; 

         .description{
              max-width:700px;
         }
   }

   .mediumn-title span, 
   .large-title span, 
   .small-title span{
       color:var(--purple);
   }


      
`;

export default Companies
