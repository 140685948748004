import React from 'react'
import { styled } from 'styled-components'
import Cover from '../../Assets/images/shape.png';
import Title from './Title';
import SocialLinks from './SocialLinks';
import Img from '../../Assets/images/shape.png'
import AboutImg from '../../Assets/images/agent.svg'
import { FaArrowRight } from "react-icons/fa";

function AgentsHeader() {
  return (
    <Container style={{backgroundImage:`url('${Img}')`}}>
    <div className="wrapper">
          <div className="space">
               <div className="block-text animate__animated animate__fadeInLeft"> 
                    <h1 className='large-title'>Seja um Agente Movicel </h1>
                    <p>
                      A Movicel oferece-te a oportunidade de criares o teu próprio emprego com perspectivas de grande crescimento.
                      Mais informações numa loja Movicel ou liga grátis 19191 e segue as instruções da operadora.
                      Com atitude e espírito empreendedor vamos longe!
                    </p>
                    <button className="btn btn-icon bg-primary btn-circle">Saiba mais <div><div className='icon'><FaArrowRight/></div></div></button>
               </div>
               <div className="imageBox animate__animated animate__fadeInRight">
                  <img src={AboutImg} alt="" />
               </div>
          </div>
    </div> 
    <SocialLinks/>
</Container>
  )
}


const Container = styled.div`
    width:100%;
    min-height:708px;
    padding-top:200px;
    background-color:var(--bluedark);
    margin-bottom:20px; 
    background-position: center;
    background-repeat: no-repeat;
    background-size:cover;
    position:relative;

    .space{
        display:flex;
        align-items:center;
        
        .imageBox{ 
           padding-left:20px;

           img{
                width:100%;
                min-width:200px;  
                max-width:500px;
            }
        } 

        
    }
 
   

    .block-text{   
          .large-title{
              max-width:700px;
          }
     
          p{
            max-width:650px;
            color:var(--white);
          }
    }

 

`;


export default AgentsHeader
