import React from 'react'
import { styled } from 'styled-components';
import {BsCheck} from "react-icons/bs";
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import {FaArrowRight}  from "react-icons/fa";

function PlansCards() {

    const Data = [
        {
          title:'Kargas', 
           price:'3.000Kz', 
           link:"/kargas",
           small_description:'plano basico para clientes',
           type:'plano mensal',
           features:[
            '20mn para falar das 06-19h',
            '123 mensagens das 14-19H',
            '134GB de dados moveis',
            '500 minutos de voz',
            '30 mensasgens das 00-12H',
           ],
        },
        {
            title:'Kuias', 
             price:'2.000Kz', 
             link:"/kuias",
             small_description:'plano basico para clientes',
             type:'plano diario',
             features:[
                '20mn para falar das 06-19h',
                '123 mensagens das 14-19H',
                '134GB de dados moveis',
                '500 minutos de voz',
                '30 mensasgens das 00-12H',
             ],
          },
        {
            title:'Movinet', 
             price:'5.000Kz',
             link:"/movinet", 
             small_description:'plano basico para clientes',
             type:'plano mensal', 
             active:true, 
             features:[
                '20mn para falar das 06-19h',
                '123 mensagens das 14-19H',
                '134GB de dados moveis',
                '500 minutos de voz',
                '30 mensasgens das 00-12H',
             ],
          },
          {
            title:'Spyka', 
             price:'10.000Kz', 
             link:"/spyka",
             small_description:'plano basico para clientes',
             type:'plano mensal',
             features:[
                '20mn para falar das 06-19h',
                '123 mensagens das 14-19H',
                '134GB de dados moveis',
                '500 minutos de voz',
                '30 mensasgens das 00-12H',
             ],
          },
          {
            title:'Aditivos', 
             price:'12.000Kz', 
             link:"/spyka",
             small_description:'plano basico para clientes',
             type:'plano mensal',
             features:[
                '20mn para falar das 06-19h',
                '123 mensagens das 14-19H',
                '134GB de dados moveis',
                '500 minutos de voz',
                '30 mensasgens das 00-12H',
             ],
          },
    ];

      
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow:4.15,
        slidesToScroll:4.15
      };

  return (
      <Container>
         <Slider  {...settings}> 
         {Data.map((item, index)=>{
                return(
                  <div className={item.active ? "card-box active animate__animated animate__fadeInRight" 
                  : "card-box animate__animated animate__fadeInRight"} key={index}>
                          <div className="space">
                              <div>
                                 <h2>{item.title}</h2>
                              </div>
                              {item.active ? <div className='pl'><BsCheck/></div> : <></>}
                          </div>
                       <div className="sm"> <span>{item.small_description}</span></div>
                       <h3>Recursos</h3>
                       <span>Tudo que precisas para te conectares com amigos</span>
                       <ul>
                           {item.features.map((ft, i)=>{
                              return(
                                 <li key={i}><div className="check"><BsCheck/></div>{ft}</li>
                              )
                           })
                           }
                       </ul>
                       <hr />
                       <div className="d-flex">
                            <h4>{item.price}</h4>
                              <div className="block">
                                  <div><small>Por cliente</small></div>
                                  <div><small>{item.type}</small></div>
                              </div>
                          </div> 
                       <Link to="#"><button className="btn">Saiba mais</button></Link>
                   </div>
                )
            })}
         </Slider> 
      </Container>
  )
}

const Container = styled.div` 
    margin:30px 0px;

    .slick-slide{ 
        padding:6px 20px  !important;
    }

    .card-box{
        min-width:290px;
        width:290px;
        min-height:500px;
        background:#ffff; 
        margin:10px 0px !important;
        -webkit-box-shadow: -3.119px 14.672px 18px 0px rgba(90, 90, 90, 0.15);
        box-shadow: -3.119px 14.672px 18px 0px rgba(90, 90, 90, 0.15); 
        border-radius:4px;
        padding:20px;

        .space{
              width:100%;
              display:flex;
              align-items:center;
              margin-bottom:15px;
              justify-content:space-between;

              h2{
                  font-size:18px;
                  margin:0px;
                  font-weight:bolder;
                  color:var(--bluedark);
              }

              .pl{
                  padding:4px;
                  border-radius:30px;
                  font-size:10px;
                  font-weight:bolder;
                  margin-top:-13px;

                   svg{
                      width:22px;
                      height:22px;
                   }
              }
        }

        .check, .pl{
            background:var(--red) !important;
        }

        button{
              background:var(--red);
              width:100%;
              margin:10px 0px;
              color:#ffff;
        }

        .d-flex{
            align-items:center;
            margin-bottom:20px;
                
            h4{
                margin-right:10px;
                font-weight:bolder;
                font-size:25px;
                color:var(--bluedark);
            } 

            small{
                margin:0px;
            }
        }

        small, span, li{
            font-size:14px;
            color:var(--bluedark);
        }

        h3{
            color:var(--bluedark);
            font-weight:bolder;
            font-size:18px;
            margin-top:20px; 
            text-transform: uppercase;
        }

        ul{
            padding:0px;
            margin:0px;
            padding-top:10px;

             li{
                  display:flex;
                  align-items:center;
                  margin:10px 0px;

                .check{
                    margin-right:10px;
                    width:15px;
                    height:15px;
                    border-radius:100%;
                    display:flex;
                    align-items:center;
                    justify-content:center;

                     svg{
                          min-width:15px;
                          min-height:15px;
                          fill:#ffff;
                     }
                  }
             }
        }




         
        &:hover{ 
            background:var(--red) !important;
            color:#ffff;
   
            h2,h4,h3{
               color:#ffff;
            }
   
            small, span, li{
               color:#ffff;
           }
   
            button{
               background:#ffff;
               color:var(--bluedark);
            }

            .check, .pl{
                background:var(--bluedark) !important;
            }
        }


    
    }

    .card-box.active{
        background:var(--red);
        color:#ffff;

        h2,h4,h3{
           color:#ffff;
        }

        small, span, li{
           color:#ffff;
       }

        button{
           background:#ffff;
           color:var(--bluedark);
        }

        .check, .pl{
            background:var(--bluedark) !important;
        }
        
        &:hover{
            background:var(--bluedark) !important;
            color:#ffff;

            h2,h4,h3{
                color:#ffff;
            }

            small, span, li{
                color:#ffff;
            }

            .check, .pl{
                background:var(--red) !important;
            }

            button{
                background:#ffff;
                color:var(--bluedark);
            }
        }
    }
`;

export default PlansCards
