import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Intro from './Intro';
import AboutPage from './Pages/AboutPage';
import NotFounded from './Pages/NotFounded';
import Store from './Pages/Store';
import ContactPage from './Pages/ContactPage';
import ServicePlans from './Pages/ServicePlans';
import OffersPage from './Pages/OffersPage';
import Companies from './Pages/Companies';
import AgentsPage from './Pages/AgentsPage';
import PortalMain from './Portal/Pages/PortalMain';
import Services from './Pages/Services';
import Terms from './Pages/Terms'; 
import HeadquartersPage from './Pages/HeadquartersPage';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Intro />} /> 
        <Route path="/store" element={<Store />} /> 
        <Route path="/contacts" element={<ContactPage />} /> 
        <Route path="*" element={<NotFounded />} /> 
        <Route path="/about" element={<AboutPage />} />
        <Route path="/service_plans" element={<ServicePlans />} />
        <Route path="/offers" element={<OffersPage />} />
        <Route path="/companies" element={<Companies />} />
        <Route path='/agents' element={<AgentsPage />}/>
        <Route path='/services' element={<Services />}/>
        <Route path='/mymovicel' element={<PortalMain />}/>
        <Route path='/terms_and_conditions' element={<Terms />}/> 
        <Route path='/stores' element={<HeadquartersPage />}/> 
      </Routes>
    </Router>
  );
}

export default App;
