import React from 'react' 
import { styled } from 'styled-components';
import {FaAngleDoubleRight, FaArrowRight, FaPlay} from "react-icons/fa";

function CompaniesBanner() {
  return (
    <Container >
          <div className="image">
               <img src="assets/img/sz2.gif" alt="" />
          </div>
          <div className="block"> 
              <div className="apbc-title-area wow fadeInUp"> 
                   <div className="apbc-headline">
                       <h3>Soluções de comunicação que ajudam o seu negócio a crescer</h3>
                   </div>
                 </div>
              <p>Conheça o Plano Super Easy , o plano que oferece 10 GB de Internet, Chamadas e SMS para todas as redes. <br />
                O Sucesso para o seu negócio ficou mais Easy!
               </p>
              <div className="apbc-secondary-btn">
                <a className='bg-bl' href="#mov_planos">Saiba mais <span><div className='ml-2'><div className='icon'><FaArrowRight/></div></div></span></a>
            </div>
          </div>
    </Container>
  )
}


const Container = styled.div` 
   display:flex;
   margin:20px 0px;

    img{
          width:600px; 
          height:480px; 
    }

    .block{
        padding-left:40px;
    }

      
`;

export default CompaniesBanner
