import React from 'react'
import { styled } from 'styled-components'
import {FaArrowRight}  from "react-icons/fa";

function AgentRequirementsSection2() {
    return (
        <div>
             <Container>
             <section className="apbc-service-section" >
                 <div className="container">
                   <div className="row">
                     <div className="col-lg-6">
                        <br /><br /><br />
                       <div className="apbc-title-area wow fadeInUp">
                         <span className="apbc-subtitle">Documentos</span>
                         <div className="apbc-headline">
                           <h3>Como posso ser um <br /> Agente Movicel ?</h3>
                         </div>
                       </div>
                     </div>
                     <div className="col-lg-6 align-self-end">
                       {/**button */}
                     </div>
                   </div>
                   <div >
                   <div className="apbc-service-slider"> 
    
                     <div className="apbc-service-single">
                       <div className="apbc-img-wrapper">
                          <img src="assets/img/sz1.png" alt=""/>
                          <div className="image-over">
                              <div className="badge bg-primary">Cartão De Contribuinte</div>
                          </div>
                       </div> 
                       <div className="apbc-service-content">
                         <div className="apbc-headline">
                           <a href="#"><h5>Cartão De Contribuinte</h5></a>
                         </div> 
                       </div>
                     </div> 
                     <div className="apbc-service-single">
                       <div className="apbc-img-wrapper">
                          <img src="assets/img/sz1.png" alt=""/>
                          <div className="image-over">
                              <div className="badge bg-primary">B.I</div>
                          </div>
                       </div> 
                       <div className="apbc-service-content">
                         <div className="apbc-headline">
                           <a href="#"><h5>Bilhete De Identidade</h5></a>
                         </div> 
                       </div>
                     </div> 
                     <div className="apbc-service-single">
                       <div className="apbc-img-wrapper">
                          <img src="assets/img/sz1.png" alt=""/>
                          <div className="image-over">
                              <div className="badge bg-primary">Clique aqui</div>
                          </div>
                       </div> 
                       <div className="apbc-service-content">
                         <div className="apbc-headline">
                           <a href="#"><h5>Preenchimento de Formulário Próprio para Agente</h5></a>
                         </div> 
                       </div>
                     </div> 
                     
                      
     
                    </div> 
                    <br /><br />
                   </div>
                 </div>
               </section>  
             </Container>
        </div>
    );
}

const Container = styled.div`

.apbc-service-slider{
    display:flex; 
    justify-content:space-between;

    
    .apbc-service-single{ 
      padding:20px;
      width:410px;   
      background:#ffff;
      
      .image-over{
           position:absolute;
           bottom:-40px;
           left:20px;
           transition:all 1s ease-in-out;

             .badge{
                padding:8px 10px; 
             }
      }

         .apbc-img-wrapper{
             min-height:200px;
             position:relative; 
             overflow:hidden;

             &:hover{
                .image-over{
                      bottom:20px;
                }
             }
         } 
     } 
  }
`;

export default AgentRequirementsSection2
