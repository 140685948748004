import React from 'react'
import MvNavbar from './Components/MvNavbar'
import { styled } from 'styled-components' 
import Footer from './Components/Footer';
import ScrollTop from './Components/ScrollTop';
import OffersSection from './Components/OffersSection';
import OffersCards from './Components/OffersCards';

function OffersPage() {
    document.title = "Ofertas empresas";
    return (
        <div>
        <MvNavbar active={4} black />
        <div className="wrapper">
            <br /><br /> 
            <br /><br /> 
            <br />
            <Container>
                <div className="wrapper">
                <div className="animate__animated animate__fadeInLeft  large-title">As melhores esperiencias virtuais com <br /> os melhores produtos digitais </div>
                <br />
                   <div className="offers-block ">
                       <div className="image animate__animated animate__fadeInLeft ">
                            <img src="assets/img/sz1.png" alt="" />
                       </div>
                       <div className="text animate__animated animate__fadeInRight">
                           <div className="mediumn-title">Apple iPhone 12, 256GB, azul marinho - Unlocked (Renewed Premium)</div>
                           <div className="price">Preço : <span>678.000.00 Kz</span></div>
                           <hr />
                           <div className="description">
                                <strong>Uma experiência como nova. Apoiado por uma garantia</strong> de satisfação de um ano.
                                Este produto Premium Renovado é enviado e vendido pela Amazon e foi certificado pela Amazon para funcionar e parecer novo. Com pelo menos 90% de duração da bateria, ele vem em luxo,
                                Embalagem da marca Amazon e tem garantia de um ano e suporte técnico. Veja os termos aqui.
                           </div>
                             <ul>
                                <li className="d-flex"><strong>Marca - </strong> Apple</li>
                                <li className="d-flex"><strong>Nome do modelo - </strong> Iphone 12</li>
                                <li className="d-flex"><strong>Sistema operativo - </strong> IOS 14</li>
                                <li className="d-flex"><strong>Capacidade de armazenamento da memoria - </strong> 256 GB</li>
                                <li className="d-flex"><strong>Cor - </strong> Vermelho</li>
                                <li className="d-flex"><strong>Tamanho da tela - </strong> 6.1 Cm</li> 
                             </ul>
                       </div>
                   </div>
                   <div className="banner animate__animated animate__fadeInUp">
                      <a href="#"> <img src="https://movicel.co.ao/uploads/5f52d40351fe12d706b16b5e3b20d219.jpeg" alt="" /></a>
                    </div>
                  <OffersSection /> 
                  <OffersCards />
                </div> 
            </Container>
        </div>
        <Footer/>
        <ScrollTop />
       </div>
      )
    }
    
    const Container = styled.div`
        width:100%;
        display:flex;
        margin:20px 0px;
    
         .large-title{
              color:var(--bluedark);
         }

         .apbc-project-section{
            padding-top:80px;
         }


        .banner{
            img{
                margin-top:50px;
                max-height:450px;
                object-fit:cover;
            }
        }

         .offers-block{
              display:flex;
              margin:20px 0px;
              
              .image{
                padding-right:30px;
                min-width:600px;
                width:600px;
                height:500px; 

                   img{
                       width:100%;
                       height:100%;
                       border-radius:6px;
                   }
              }

              .text{
                  .price{
                       font-size:20px;
                       color:var(--bluedark);
                       font-weight:600;
                       margin:10px 0px;

                       span{
                          color:var(--red);
                       }
                  }

                  .description{
                      font-size:16px;
                  }

                  strong{
                    color:var(--bluedark);
                  }

                  ul{
                    padding:0px;

                      li{
                          margin:10px 0px;

                            strong{
                                  margin-right:10px;
                            }
                      }

                  }


              }
         }
    `;
export default OffersPage
