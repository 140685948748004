import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { styled } from 'styled-components';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function PlayerModal(props) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
  
    return (
      <div>
        <div onClick={handleClickOpen}>
           {props.toggle_btn ? props.toggle_btn : <></>}
        </div>
        <Dialog  open={open} TransitionComponent={Transition}  keepMounted  onClose={handleClose}  aria-describedby="alert-dialog-slide-description"  >
          <DialogContent>
            <Container className='video-frame'>
              <iframe 
                  src="https://www.youtube.com/embed/qT7CPZvOZgQ" title="YouTube video player"
                  frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                  allowfullscreen>
              </iframe>
            </Container>
          </DialogContent> 
        </Dialog>
      </div>
    );
}

export default PlayerModal


const Container = styled.div`

   iframe{
       width:100%;
       min-width:550px;
       height:400px;
   }

`;