import React from 'react'
import Table from 'react-bootstrap/Table';
import { styled } from 'styled-components';
import {MdSignalWifiStatusbarConnectedNoInternet2} from "react-icons/md";
import {BiMessageSquareDetail} from "react-icons/bi"
import {RiUserVoiceLine} from "react-icons/ri";

function Ptconsumption() {

  const Dashbox = [
      {title:"Mensagens (SMS)", link:"#", value:23, description:"2.000 UTTS", icon:<BiMessageSquareDetail/> },
      {title:"Internet", link:"#", value:23, description:"14.000 GB", icon:<MdSignalWifiStatusbarConnectedNoInternet2/> },
      {title:"Saldo de voz", link:"#", value:23, description:"134.000 UTTS", icon:<RiUserVoiceLine/> },
  ];


  return (
    <Container>
        <div className="dashboxes">
             {Dashbox.map((item, index)=>{
                return(
                  <div key={index} className="box">
                      <div className="icon">
                          {item.icon}
                      </div>
                      <div className="block">
                          <h2>{item.title}</h2>
                          <span>{item.description}</span>
                      </div>
                  </div>
                )
             })

             }
        </div>
        <br />
        <Table className='text-center' bordered hover>
        <thead>
            <tr>
            <th>Data</th>
            <th>Tipo</th>
            <th>Nº destino</th>
            <th>Consumo</th>
            </tr>
        </thead>
        <tbody>
           {[1,2,3,4,5,6].map((item, index)=>{
                return(
                 <tr key={index} >
                    <td>10-01-2023</td>
                    <td>Saldo de voz</td>
                    <td>990 000 000</td>
                    <td>14 UTTS</td>
                  </tr> 
                );
           })}
        </tbody>
        </Table>
        <br />
    </Container>
  )
}


const Container = styled.div`
    .dashboxes{
      margin:10px 0px;
      display:flex;
      justify-content:space-between;

        .box{
              width:32%;
              height:150px;
              background:#ffff;
              border-radius:10px;
              box-shadow:0px 3px 10px rgba(0,0,0,0.1);
              display:flex;
              padding:20px;
              align-items:center;

              h2{
                font-size:20px;
                color:var(--bluedark);
              }

              .icon{
                  margin-right:20px;
                  width:70px;
                  min-width:70px;
                  height:70px;
                  border-radius:100%;
                  background:red;
                  display:flex; 
                  align-items:center;
                  justify-content:center;
                  background: linear-gradient(to  top, var(--red) 10%, var(--bluedark));

                  svg{
                       width:30px;
                       height:30px;
                       color:#ffff;
                  }
              }
        }
    }
`;


export default Ptconsumption
