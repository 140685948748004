import React from 'react'
import Slider from 'react-slick';
import { styled } from 'styled-components';
import {FaAngleDoubleRight, FaArrowRight, FaPlay} from "react-icons/fa";
import SocialLinks from './SocialLinks';

const  Images = [
    require("../../Assets/images/slider/slider1.png"),
    require("../../Assets/img/business-4/slider/slider-1.png"),
    require("../../Assets/img/business-4/slider/slider-1.png"),
    require("../../Assets/img/business-4/slider/shape-1.png"),
    require("../../Assets/img/business-4/slider/shape-2.png"),
]; 

function HomeHeader() {
   
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    margin:0,
    slidesToScroll:1
  };

  return (
    <Container>  
        <Slider  {...settings} className="apbc-hero-slider" id="apbc_banner">
               <div className="apbc-single-item s1" style={{backgroundImage:`url(${Images[0]})`}}>
                 <span className="apbc-slide-shape-1"><img src={Images[0]} alt=""/></span>
                 <span className="apbc-slide-shape-2"><img src={Images[0]} alt=""/></span> 
                 <div className="container">
                   <div className="row">
                     <div className="col-lg-7">
                       <div className="apbc-slide-left">
                         <div className="apbc-headline">
                           <h1> O sucesso do seu Negócio; passa pela Movicel.</h1>
                         </div>
                         <div className="apbc-pera-txt">
                           <p>Com a Movicel tens os melhores preços o melhor atendimento e os melhores benefícios.</p>
                         </div>
                         <div className="apbc-primary-btn">
                           <a href="#mov_planos">Saiba mais <span><div className='ml-2'><div className='icon'><FaArrowRight/></div></div></span></a>
                         </div>
                       </div>
                     </div>
                   </div>
                 </div>
               </div>

           
               <div className="apbc-single-item s2">
                 <span className="apbc-slide-shape-1"><img src={Images[3]} alt=""/></span>
                 <span className="apbc-slide-shape-2"><img src={Images[4]} alt=""/></span>
                 <div className="container">
                   <div className="row">
                     <div className="col-lg-7">
                       <div className="apbc-slide-left">
                         <div className="apbc-headline">
                           <h1>Somos a primeira operadora de Angola</h1>
                         </div>
                         <div className="apbc-pera-txt">
                           <p>Com três pilares: Inovação, Qualidade e Experiência de Usuário. O nosso trabalho é fazer parte do teu dia a dia e também colaborar, através das nossas ligações, para que este mundo seja cada vez melhor.</p>
                         </div>
                         <div className="apbc-primary-btn">
                           <a href="#mov_sobre">Sobre nós <span><div className='ml-2'><div className='icon'><FaArrowRight/></div></div></span></a>
                         </div>
                       </div>
                     </div>
                   </div>
                 </div>
               </div>
             
               <div className="apbc-single-item s3">
                 <span className="apbc-slide-shape-1"><img src={Images[3]} alt=""/></span>
                 <span className="apbc-slide-shape-2"><img src={Images[4]} alt=""/></span> 
                 <div className="container">
                   <div className="row">
                     <div className="col-lg-7">
                       <div className="apbc-slide-left">
                         <div className="apbc-headline">
                           <h1>Agora use Multicaixa Express sem gastares dados</h1>
                         </div>
                         <div className="apbc-pera-txt">
                           <p>Na Movicel podes fazer operações no MULTICAIXA EXPRESS sem gastares dados. Mais conforto e agilidade. #Movicel #Vamoslonge</p>
                         </div>
                       </div>
                     </div>
                   </div>
                 </div>
               </div>
               
        </Slider>
        <SocialLinks />
    </Container>
  )
}

const Container = styled.div` 
position:relative;

.apbc-single-item{  
    background-repeat:no-repeat;
    background-size:cover;
    background-position: right;
    min-height:908px;
    max-height:908px; 
  }

  .apbc-single-item.s1{
    background-image:url('assets/img/business-4/slider/slider2.jpeg');
  }
  .apbc-single-item.s2{
    background-image:url('assets/img/business-4/slider/slider3.jpeg');
  }
  .apbc-single-item.s3{
    background-image:url('assets/img/business-4/slider/slider2.jpeg');
  }



   .apbc-primary-btn .icon{
        width:40px;
        min-width:40px;
        height:40px;
        border-radius:100%;
        margin-left:10px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        background:var(--white);

        svg{
            fill:var(--red);
        }
   }
`;

export default HomeHeader
