import React from 'react'
import { styled } from 'styled-components'
import Cover from '../../Assets/images/shape.png';
import Title from './Title';
import SocialLinks from './SocialLinks';
import Img from '../../Assets/images/shape.png'
import AboutImg from '../../Assets/images/about.png'
import { FaArrowRight } from "react-icons/fa";

function PlansHeader({content}) {
    return (
        <Container style={{backgroundImage:`url('${Img}')`}}>
        <div className="wrapper">
             {content}
        </div> 
        <SocialLinks/>
    </Container>
    )
}


const Container = styled.div`
    width:100%;
    height:600px;
    max-height:600px;
    padding-top:120px;
    background-color:var(--bluedark);
    margin-bottom:20px; 
    background-position: center;
    background-repeat: no-repeat;
    background-size:cover;
    position:relative;

    .space{
        display:flex;
        align-items:center;

          img{
             width:100%;
             min-width:200px;  
             max-width:555px;
          }
    }
 
   

    .block-text{  
          .large-title{
              max-width:700px;
          }
     
          p{
            max-width:650px;
            color:var(--white);
          }
    }

 

`;


export default PlansHeader
