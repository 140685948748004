import React from 'react'
import MvNavbar from './Components/MvNavbar';
import { styled } from 'styled-components';
import Footer from './Components/Footer';
import ScrollTop from './Components/ScrollTop';
import { FaArrowRight } from "react-icons/fa";
import { Link } from 'react-router-dom';

function HeadquartersPage() {
    document.title = "Nossas Lojas";
    return (
        <div>
        <MvNavbar active={4} black />
        <div className="wrapper">
            <br /><br /> 
            <br /><br /> 
            <br />
            <Container>
                <div className="wrapper">
                <div className="animate__animated animate__fadeInLeft  large-title">Conheça as Nossas lojas <br /> dentro do territorio nacional </div>
            
                 <div>
                <section className="apbc-team-section" id="mov_ofertas">
                    <div className="container"> 
                    <div className="apbc-team-content">
                        <div className="apbc-team-carousel">
                    

                            {[1,2,3,4].map((item, index)=>{
                                return(     
                                <div key={index+1} className="apbc-team-single">
                              <div className="map">
                              <div className="apbc-img-wrapper"> 
                                <div  >
                                    <div id="display-google-map">
                                        <iframe   frameborder="0"
                                         src="https://www.google.com/maps/embed/v1/place?q=belas+shopping&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8">
                                        </iframe>
                                    </div>
                                </div>
                                </div>
                              </div>
                                <div className="apbc-member-content">
                                    <div className="apbc-headline">
                                    <a href="#"><h6>Loja Unibelas depois da rua do emirais</h6></a>
                                    </div>
                                    <div className="apbc-designation">
                                         <span></span>
                                    </div>
                                </div>
                                </div>)
                            })}

                    

                        </div>
                        </div>
                        </div>
                    </section> 
                    </div>
                 <br />
                    <div className="contact-banner">
                          <h1>Fale conosco !</h1>
                          <p>Linha Apoio ao cliente Atendimento para informações gerais, por assistentes especializados e sempre dispostos a ajudar.</p>
                          <div className="apbc-secondary-btn">
                              <Link to="/contacts">Saiba mais <span><div className='ml-2'><div className='icon'><FaArrowRight/></div></div></span></Link>
                         </div>
                    </div>
                    <br />
                </div>  
            </Container>
        </div>
        <Footer/>
        <ScrollTop />
       </div>
      )
    }
    
    const Container = styled.div`
        width:100%;
        display:flex;
        margin:20px 0px;
    
         .large-title{
              color:var(--bluedark);
         }

       .map{
          padding:10px;
          iframe{
            width:100%;
            min-eight:340px;
            height:340px;
          }
       }


         .contact-banner{
            padding:40px;
            border-radius:20px;
            text-align:center;
            color:var(--white);
            background:var(--red);
            display:flex;
            align-items:center;
            justify-content:center;
            flex-direction:column;

            h1{
                font-family: "Poppins", sans-serif;
                font-weight: 700;
                text-transform: capitalize;
                line-height: 1.25;
                letter-spacing:-1px;
                margin-bottom:20px;
                color:var(--white);
            }

               p{
                  max-width:700px;
               }
         }

         
    `;

export default HeadquartersPage