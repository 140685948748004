import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';


function PtMyArea() {
    return (
        <Form>
          <div className="d-flex col">
            <Form.Group className="mb-3 col" controlId="formBasicEmail">
                <Form.Label>Data de nascimento</Form.Label>
                <Form.Control type="text" placeholder="..." /> 
            </Form.Group>
            <div className="pd"></div>
            <Form.Group className="mb-3 col" controlId="formBasicEmail">
                <Form.Label>Nacionalidade</Form.Label>
                <Form.Control type="text" placeholder="..." /> 
            </Form.Group>
           </div>
           <div className="d-flex col">
            <Form.Group className="mb-3 col" controlId="formBasicEmail">
                <Form.Label>Municipio</Form.Label>
                <Form.Control type="text" placeholder="..." /> 
            </Form.Group>
            <div className="pd"></div>
            <Form.Group className="mb-3 col" controlId="formBasicEmail">
                <Form.Label>Genero</Form.Label>
                <Form.Control type="text" placeholder="..." /> 
            </Form.Group>
           </div>
           <div className="d-flex col">
            <Form.Group className="mb-3 col" controlId="formBasicEmail">
                <Form.Label>Provincia</Form.Label>
                <Form.Control type="text" placeholder="..." /> 
            </Form.Group>
            <div className="pd"></div>
            <Form.Group className="mb-3 col" controlId="formBasicEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control type="text" placeholder="..." /> 
            </Form.Group>
           </div>
           <div className="d-flex col">
            <Form.Group className="mb-3 col" controlId="formBasicEmail">
                <Form.Label>Morada</Form.Label>
                <Form.Control type="text" placeholder="..." /> 
            </Form.Group>
            <div className="pd"></div>
            <Form.Group className="mb-3 col" controlId="formBasicEmail">
                <Form.Label>Telefone fixo</Form.Label>
                <Form.Control type="text" placeholder="..." /> 
            </Form.Group>
           </div>
         <div className="space">
              <div></div>
              <Button className='btn bg-primary'  type="submit">Salvar</Button>
         </div>
         <br />
        </Form>
      );
}

export default PtMyArea
