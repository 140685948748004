import React from 'react'
import MvNavbar from './Components/MvNavbar'
import { styled } from 'styled-components' 
import Footer from './Components/Footer';
import ScrollTop from './Components/ScrollTop';
import PlansCards from './Components/PlansCards';
import ContactQuestions from './Components/ContactQuestions'
import PlansHeader from './Components/PlansHeader';


function ServicePlans() {
  document.title = "Planos";
  return (
    <div>
    <MvNavbar active={2} />  
        <PlansHeader content={<>
          <Container> 
          <div className="wrapper">
            <div className="animate__animated animate__fadeInLeft  large-title">Temos o melhor dos planos,  que <br /> se encaixam perfeitamente consigo </div>
              <br />
              <PlansCards/>  
           </div>
        </Container> 
        </> } />
        <br /><br />
        <br /><br />
        <br /><br />
        <br /><br />
        <br /><br /> 
      <ContactQuestions /> 
    <Footer/>
    <ScrollTop />
   </div>
  )
}

const Container = styled.div`
    width:100%;
    display:flex;
    margin-bottom:-100px;
 
 
`;


export default ServicePlans
