import React from 'react' 
import { Form } from 'react-bootstrap';
import { styled } from 'styled-components'

function AgentsForm() {

    const Send = ()=>{

    }


  return (
   <div className="wrapper">
    <br />
        <Container>
        <div className="header-title">
        <div className="apbc-title-area wow fadeInUp">
              <span className="apbc-subtitle">Suporte</span>
              <div className="apbc-headline">
                <h3>Precisa De Alguma Ajuda?</h3>
              </div> 
            </div>
        </div>
        <div className="container-box">
        <div className="contact-form animate__animated animate__fadeInRight">
          <Form action='' onSubmit={()=>Send()} >
                <div className="space-form"> 
                    <Form.Control placeholder='Nome' />
                </div>
                <div className="space-form"> 
                    <Form.Control placeholder='Website' />
                </div>
                <div className="space-form"> 
                    <Form.Control placeholder='Email' />
                </div>
                <div className="space-form"> 
                    <Form.Control placeholder='Assunto' />
                </div>
                <div className="space-form"> 
                    <Form.Control as="textarea" rows="10"  placeholder='Descrição' />
                </div>
                <div>
                <button  type='submit' className="btn bg-primary text-light btn-circle">Enviar</button>
                </div>
            </Form>
            </div>
            <div className="image">
                  <img src="assets/img/sz2.gif" alt="" />
            </div>
        </div>
     </Container>
     <br /><br />
   </div>
  )
}

const Container = styled.div` 
width:100%;
min-width:490px;
background:var(--white);
padding:20px; 
box-shadow:0px 3px 10px rgba(0,0,0,0.1);
border-radius:10px;

.container-box{
     display:flex;

     .image{
         width:600px;
         min-width:600px;
         height:540px;  


          img{
              width:100%;
              height:100%;
          }
     }
}
   
.contact-form{  
    width:100%;

    .flex-end{
        display:flex; 
        justify-content:flex-end;
        width:100%;
    }


      form{
        width:100%;
        padding-right:20px;
      }

      .space-form{
             display:flex;  
             margin:10px;

              input, textarea{ 
                  width:100%;
                   border:none;
                   background:var(--white-smoke);
                   margin-bottom:10px;
              }


              label{
                 color:var(--bluedark);
              }
      }
}



`;

export default AgentsForm
