import React from 'react'
import MvNavbar from './Components/MvNavbar'
import AboutHeader from './Components/AboutHeader'
import { styled } from 'styled-components'
import ScrollTop from './Components/ScrollTop';
import Footer from './Components/Footer';
import AgentsHeader from './Components/AgentsHeader';
import AgentRequirementsSection2 from './Components/AgentRequirementsSection2';
import AgentsAbout from './Components/AgentsAbout';
import AgentsForm from './Components/AgentsForm';
import TermsHeader from './Components/TermsHeader';
import { MdDownloading } from "react-icons/md";
const file = require("../Assets/TERMOS_E_CONDICOES.pdf");

function Terms() {
  document.title = "Termos e condições";

  return (
    <Container>
    <MvNavbar active={3} />
      <TermsHeader />
       <br />
       <div className="wrapper">
          <div className="apbc-title-area wow text-center fadeInUp"> 
                <div className="apbc-headline">
                    <a download href={file}>
                        <button className="btn bg-primary text-light"><MdDownloading /> Baixar Termos e condiçõs</button>
                    </a>
                </div>
              </div> 
          </div>
       <br />
    <Footer />
    <ScrollTop />
  </Container>
  )
}

const Container = styled.div`


svg{
    font-size:30px;
}

.apbc-about-section .apbc-img-wrapper{
    min-height:600px;  
    background:#E7E9EF;
  
      img{
         width:100%;
      }
  }
  
`;


export default Terms
