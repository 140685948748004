import React from 'react'
import Slider from 'react-slick';
import { styled } from 'styled-components'

function AboutCarousel() {

 const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 6,
      slidesToScroll: 1,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 2000,
      cssEase: "linear"
 }; 

  return (
     <Container>
         <div className="wrapper">
         <div className="apbc-title-area wow fadeInUp"> 
            <div className="apbc-headline">
                <br />
                <h3>Parcerias que dão orgulho </h3>
            </div>
          </div>  
             <Slider {...settings}>
                 {['','','','','','','','','','','','','','',''].map((item, index)=>{
                    return(
                         <div key={index} className="box">
                              <div className="content">

                              </div>
                         </div>
                    )
                 })}
             </Slider> 
         </div>
     </Container>
  )
}

const Container = styled.div`
   padding:30px 0px;
  margin-bottom:20px;

     .box{
          padding-right:20px;  
          padding-top:20px;

          .content{ 
             background:var(--white-smoke);
             width:100%;
             height:100px;
          }
     }
`;

export default AboutCarousel
