import React from 'react'
import {FaAngleDoubleRight, FaArrowRight, FaPlay} from "react-icons/fa"; 

function AboutButton() {
  return (
    <div>
      <div className="apbc-primary-btn">
          <a href="#mov_planos">Saiba mais <span><div className='ml-2'><div className='icon'><FaArrowRight/></div></div></span></a>
        </div>
    </div>
  )
}

export default AboutButton