import React from 'react';
import { styled } from 'styled-components';


function AboutText(props){
  return (
     <Container>
        <div className="wrapper">
            <div className={`details ${props.reverse ? 'reverse' : '' } `}>
              <div className="block"> 
                    <div className="apbc-title-area wow fadeInUp"> 
                    <div className="apbc-headline">
                        <h3>{props.t1}{props.t2}</h3>
                    </div>
                   </div> 
                    <p> {props.description} </p>
                </div>
                <div className="image">
                    <img src={props.image} alt={props.t1 +  " "+ props.t2} />
                </div>
            </div>
        </div>
     </Container>
  )
}

const Container = styled.div` 
    margin:30px 0px;


       .details{
          display:flex;
          align-items:center;

            .block{
                padding-right:20px;
            }

            .image{
                min-width:500px; 

                img{
                    width:100%;
                     max-width:700px;
                     max-height:400px;
                }
            }
       }

       .details.reverse{
           flex-direction: row-reverse;

           .block{
              padding-right:0px;
              padding-left:20px; 
           }
       }

`;

export default AboutText
