import React from 'react'
import {FaAngleDoubleRight, FaArrowRight, FaPlay} from "react-icons/fa";

function OffersSection() {
  return (
    <div>
      <section className="apbc-project-section" id="mov_devices">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="apbc-title-area text-center wow fadeInUp">
                <span className="apbc-subtitle">Telefones & Aparelhos</span>
                <div className="apbc-headline">
                  <h3>Na Movicel encontras os melhores telefones, aparelhos e muito mais</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="apbc-bottom-content">
            <div className="row">
              <div className="col-lg-7 col-md-7">
                <div className="apbc-pr-column wow  fadeInUp">
                  <div className="apbc-img-wrapper">
                    <img src="assets/img/sz3.gif" alt=""/>
                  </div>
                  <div className="apbc-pr-content">
                    <div className="apbc-pr-left">
                      <a href="#"><h5>Apple iPhone 13 128GB</h5></a>
                      <a href="#"><span>Kz 1.250.000</span></a>
                    </div>
                    <div className="apbc-readmore-btn">
                      <a href="#"><div className='ml-2'><div className='icon'><FaArrowRight/></div> </div></a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-5">
                <div className="apbc-pr-column wow fadeInUp" data-wow-delay="0.1s">
                  <div className="apbc-img-wrapper">
                    <img src="assets/img/sz4.png" alt=""/>
                  </div>
                  <div className="apbc-pr-content">
                    <div className="apbc-pr-left">
                      <a href="#"><h5>Huawei Modem 4G Wireless 300MB</h5></a>
                      <a href="#"><span>Kz 35.000</span></a>
                    </div>
                    <div className="apbc-readmore-btn">
                      <a href="#"><div className='ml-2'><div className='icon'><FaArrowRight/></div> </div></a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-md-12">
                <div className="apbc-pr-column wow fadeInUp" data-wow-delay="0.2s">
                  <div className="apbc-img-wrapper">
                    <img src="assets/img/sz3.gif" alt=""/>
                  </div>
                  <div className="apbc-pr-content">
                    <div className="apbc-pr-left">
                      <a href="#"><h5>Samgsung Galaxy A12</h5></a>
                      <a href="#"><span>Kz 260.000</span></a>
                    </div>
                    <div className="apbc-readmore-btn">
                      <a href="#"><div className='ml-2'><div className='icon'><FaArrowRight/></div> </div></a>
                    </div>
                  </div>
                </div>
              </div> 
            </div>
            <div className="apbc-readmore-btn-2 wow fadeInUp">
              <a href="#">Veja mais <FaArrowRight className='ml-2'/>  </a>
            </div>
          </div>
        </div>
      </section> 
    </div>
  )
}

export default OffersSection
