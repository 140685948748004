import React from 'react'
import MvNavbar from './Components/MvNavbar'
import { styled } from 'styled-components' 
import Footer from './Components/Footer';
import ScrollTop from './Components/ScrollTop';
import PlansCards from './Components/PlansCards';
import ContactQuestions from './Components/ContactQuestions'
import PlansHeader from './Components/PlansHeader';
import ServiceHeader from './Components/ServiceHeader';
import { FaSimCard } from "react-icons/fa";
import { SiMakerbot } from "react-icons/si";
import { SiMoo } from "react-icons/si";
import CompanyMontlyPlans from './Components/CompanyMontlyPlans';
import MonthlyPlansCards from './Components/MonthlyPlansCards';



function Services() {
    document.title = "Serviços";

    const Data = [
        {title:"Movi Mais", icon: <><SiMakerbot /></>,  description:"O Facebook Flex é um serviço que permite aos clientes da Movicel acederem ao Facebook gratuitamente a qualquer momento e sem custo de dados."},
        {title:"Movi Activo", icon: <><FaSimCard/></>,  description:"O Serviço Kilapi permite pedir um crédito de 200kz para continuar a comunicar-se, caso fiques sem saldo e não tiveres a possibilidade de efectuar recarga no momento."},
        {title:"Movi Fácil", icon:<SiMoo />,  description:"Serviço que permite ao Cliente Movicel carregar saldo, quando adquirir um código de recarga num ATM, Cartão Taco ou Internet Banking."},
    ]



    return (
        <Container>
        <MvNavbar active={3} />
          <ServiceHeader />
           <div className="wrapper">
             <div className="services-list">
                {Data.map((item, index)=>{
                    return(
                    <article key={index}>
                         <div className="icon">{item.icon}</div>
                         <h4>{item.title}</h4>
                         <p>{item.description}</p>               
                    </article>);
                })}
             </div>  
           </div>
           <div className="wrapper">
                <div className="apbc-title-area wow  text-center fadeInUp"> 
                   <div className="apbc-headline">
                       <br />
                       <h3>Planos Mensais !</h3>
                   </div>
                 </div> 
             <MonthlyPlansCards  />
             <br/><br />
           </div>
           <div className="video-box">
           <div className="wrapper">
             <iframe  src="https://www.youtube.com/embed/YXh2ZjqjFXo?si=mutAlk2s2c1czCtz"
                 title="YouTube video player" 
                 frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                 allowfullscreen> 
               </iframe>
             </div>
           </div>
           <div className="plans-area">
              <div className="wrapper">
              <div className="apbc-title-area wow text-center fadeInUp"> 
                   <div className="apbc-headline">
                       <br />
                       <h3>Planos Movicel !</h3>
                   </div>
                 </div> 
                <PlansCards/>  
              </div>
           </div>
        <Footer />
        <ScrollTop />
      </Container>
      )
    }
    
const Container = styled.div`
.apbc-about-section .apbc-img-wrapper{
    min-height:600px;  
    background:#E7E9EF;
    
    img{
        width:100%;
       }
    }

    .video-box{
        width:100%;
        background: var(--bluedark);
        margin-bottom:40px;

          iframe{
              width:100% !important;
              height:550px;
              margin:0px !important;
              background:var(--red);
          }
        
    }


   .services-list{
       display:flex;
       align-items:center;
       flex-wrap:wrap;
       justify-content:center;
       width:100%;
       margin:40px 0px;


         article{
              max-width:300px;
              margin:20px;
              flex-direction:column;
              display:flex;
              align-items:center;
              text-align:center;
              justify-content:center;

              h4{
                  margin:10px 0px;
                  color:var(--red); 
              }

              p{
                 font-size:16px;
                 color:var(--bluedark);
              }

              .icon{
                  width:150px;
                  height:150px;
                  border:10px solid var(--bluedark);
                  border-radius:100%;
                  display:flex;
                  align-items:center;
                  text-align:center;
                  justify-content:center;
                  font-size:40px;    
                  color:var(--red);
              }


         }
   }


`;

export default Services
