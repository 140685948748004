import React from 'react'
import { Link } from 'react-router-dom';
import { styled } from 'styled-components';
import {BsSim,BsCalendarDate,BsCashCoin,BsTags,BsVoicemail,BsTelephone} from "react-icons/bs";
import {TbWorldWww}  from "react-icons/tb";

function PtDashboard() {
   const  links = [
        {title:"Estado do cartão", link:"#", description:"activo", dot:true, icon:<BsSim/>},
        {title:"Saldo de dados", link:"#", description:"4GB", icon:<TbWorldWww/>},
        {title:"Data limite de carregamento", link:"#", description:"24 de junho de 2022", icon:<BsCalendarDate/>},
        {title:"Debitos", link:"#", description:"Visualizar detalhes", icon:<BsCashCoin/>},
        {title:"Saldo de voz", link:"#", description:"90 UTT", icon:<BsTelephone/>},
        {title:"Tarifarios", link:"#", description:"Pré-pago fala mais", icon:<BsTags/>},
        {title:"Saldo de Bónus", link:"#", description:"Vidualizar detalhes", icon:<BsVoicemail/>},
   ];
    
  return (
    <Container>
    {links.map((item, index)=>{
          return(
            <div  key={index} className="item">
                <div className="icon">{item.icon}</div>
                <Link  to={item.link}>
                   <div className="block">
                    <h3>{item.title}</h3>
                    <div className="d-flex"> 
                        <span>{item.description}</span>
                       {item.dot ? <div className="dot bg-info"></div> : <></> }
                    </div> 
                   </div>
                 </Link>
            </div>
         )})
    }
    </Container>
  )
}



const Container = styled.div`
    display:flex;
    flex-wrap:wrap;

        .item{
            display:flex;
            margin:20px 0px;
            min-width:350px;

            .icon{
                width:65px;
                height:60px;
                margin-right:20px;

                 svg{
                      width:50px;
                      height:50px;
                      color:var(--red);
                 } 
            }


            h3{
                font-size:20px;
                color:var(--bluedark);
                margin:0px;
                margin-bottom:10px;
            }


            span{
                font-size:16px;
            }


           .d-flex{
              align-items:center;
              .dot{
                width:10px;
                height:10px;
                border-radius:100%;
                margin:0px 10px;
            }

           }

        }
`;

export default PtDashboard
