import React from 'react'
import { Link } from 'react-router-dom'
import Img from '../Assets/images/shape.png'
import { styled } from 'styled-components'

function NotFounded() {
  return (
     <Container>
         <img src={Img} alt="" />
         <div className="over">
              <h1>404</h1>
              <p>lamentamos mas não foi possivel  encomtrar esta pagina</p>
         </div>
     </Container>
  )
}

const Container = styled.div`
   min-height:100vh;
   position:relative;
   
   img{
      width:100$;
      height:100vh;
   }

   .over{
      position:absolute;
      top:0px;
      left:0px;
      width:100%;
      height:100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

        h1{
            font-size:200px;
            color:#ffff;
        }

        p{
            color:#ffff;
        }
   }

`;



export default NotFounded 