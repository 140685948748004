import React from 'react'
import { styled } from 'styled-components'
import {BsCheck} from "react-icons/bs";
import MonthlyPlansCards from './MonthlyPlansCards';

function CompanyMontlyPlans() {
        
     
  return (
    <div>
      <Container>
         <div className="cover">
                <img src="https://images.pexels.com/photos/3862632/pexels-photo-3862632.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" />
            </div>
            <div className="over">
                <h1 className='mediumn-title'>Planos Mensais</h1>
                <p>Activa um plano e recebe minutos e mensagens para Movicel,
e podes ainda adicionar minutos
e mensagens para outras redes,
dados para qualquer hora e
dados para a madrugada. </p>
                <MonthlyPlansCards mb  />
            </div> 
        </Container>
        <br /><br /><br /><br /><br /><br />
    </div>
  )
}


const Container = styled.div`
height:500px;
width:100%;
position:relative;
margin-top:60px;


 .cover{
    height:500px;
      img{
          height:100%; 
          object-fit:cover;
      }
 }


 .over{
    background: linear-gradient(to top, rgba(115, 48, 132, 0.99) 0%, rgba(0, 6, 37, 0.9) 100%);
    position:absolute;
    top:0px;
    left:0px;
    width:100%;
    height:100%;
    
    display:flex;
    align-items:center; 
    flex-direction: column;


        h1{
            margin-top:50px;
            color:var(--white);
        }

        p{
            max-width:900px;
            color:var(--white);
            text-align:center;
        }

 

       }
       
 }

`;

export default CompanyMontlyPlans
