import React from 'react'
import styled from 'styled-components'
import {BsCheck} from "react-icons/bs";

function MonthlyPlansCards(props) {
    const Data = [ 
        {
          title:'Base',  
          price:'Kz 3.700',
          features:[
            '1200 min/sms',
            '1000 min/sms',
            '1GB de dados moveis',
            'Suporte 24/24'
          ]
         },
        {
          title:'Internacional',  
          price:'Kz 4.700',
          features:[
            '1200 min/sms',
            '1000 min/sms',
            '1GB de dados moveis',
            'Suporte 24/24'
          ]
         },
        {
          title:'Outro',  
          price:'Kz 5.700',
          features:[
            '1200 min/sms',
            '1000 min/sms',
            '1GB de dados moveis',
            'Suporte 24/24'
          ]
         },
  ];

  return (
     <Container>
        <div className={props.mb ? "cards mb"  : "cards"}>
        {Data.map((item, index)=>{
            return(
                <div className={item.active ? "card-box active animate__animated animate__fadeInRight" 
                : "card-box animate__animated animate__fadeInRight"} key={index}>  
                    <h1>{item.title}</h1>
                    <div><h2>{item.price} <small> / 30 dias</small> </h2></div>  
                    <div className="bar"></div>
                    <div className="sm"> <span>{item.small_description}</span></div>
                    <ul>
                        {item.features.map((ft)=>(
                            <li><div className="bg-primary check"><BsCheck/></div>{ft}</li>
                        ))}
                    </ul>
                    <button className="btn">Solicite hoje</button> 
                </div>
                );
            })};  
        </div>
     </Container>
  )
}

const Container = styled.div`
position:relative;

.cards.mb{ 
    
    .card-box{
      margin-bottom:-460px; 
    
    h2{ 
        color:var(--purple) !important; 
    }

     button{ 
        color:var(--purple)  !important;
        border:2px solid var(--purple) !important;
     } 
    }
  }


.cards{ 
    display:flex;
      align-items:center;
      justify-content:center;
      flex-wrap:wrap; 


      .card-box{
          min-width:350px;
          width:300px;
          min-height:450px;
          background:#ffff;
          border:1px solid var(--white-smoke);
          margin:10px;  
          -webkit-box-shadow: -3.119px 14.672px 18px 0px rgba(90, 90, 90, 0.15);
          box-shadow: -3.119px 14.672px 18px 0px rgba(90, 90, 90, 0.15); 
          border-radius:4px;
          padding:20px; 
          display: flex; 
          flex-direction: column;


            h1{
              font-size:25px;
              color:var(--bluedark);
              margin:0px;
              font-weight:600;
            }

            .bar{
              width:100%;
              margin:20px 0px;
              background:silver;
              height:2px;
            }

          .icon{
              width:100px;
              height:100px; 
              display: flex;
              align-items: center;
              justify-content: center; 
              border-radius:100%;

              svg{
                  width:50px;
                  height:50px;
                  fill:var(--white);
              }
          }
  
              h2{
                  font-size:50px;
                  margin:10px 0px;
                  font-weight:bolder;
                  color:var(--red);
                  

                  small{
                        font-size:16px; 
                  }
              }

              

          button{
              background:transparent;
              width:100%;
              margin:10px 0px;
              color:var(--white); 
              margin-top:10px;
              color:var(--red);
              border:2px solid var(--red);
          }

                  
          ul{
              padding:0px;
              margin:0px; 

              li{
                  display:flex;
                  align-items:center;
                  margin:10px 0px;
                  font-size:16px;
                  color:var(--bluedark);

                  .check{
                      margin-right:10px;
                      width:15px;
                      height:15px;
                      border-radius:100%;
                      display:flex;
                      align-items:center;
                      justify-content:center;

                      svg{
                          min-width:15px;
                          min-height:15px;
                          fill:#ffff;
                      }
                  }
              }
          }
          
  
  
      }

      .card-box.active{   
          button, .icon{ 
              background:var(--bluedark) !important;
              color:var(--white);
              border-color:var(--bluedark);
          }
      }

 

`;



export default MonthlyPlansCards
