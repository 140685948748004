import React from 'react'
import { styled } from 'styled-components'
import {FaArrowRight}  from "react-icons/fa";

function AgentRequirementsSection() { 
  return (
    <div>
         <Container>
         <section className="apbc-service-section" >
             <div className="container">
               <div className="row">
                 <div className="col-lg-6">
                    <br /><br /><br />
                   <div className="apbc-title-area wow fadeInUp">
                     <span className="apbc-subtitle">Sobre Agentes</span>
                     <div className="apbc-headline">
                       <h3>
                         Sabe O Que É Necessário <br />
                         Para Ser Um Agente Movicel
                       </h3>
                     </div>
                   </div>
                 </div>
                 <div className="col-lg-6 align-self-end">
                   <div className="apbc-secondary-btn  wow fadeInUp">
                     <a href="#" className='bg-primary'>Mais Planos<div className='icon'><FaArrowRight/></div></a>
                   </div>
                 </div>
               </div>
               <div >
               <div className="apbc-service-slider"> 

                 <div className="apbc-service-single">
                   <div className="apbc-img-wrapper">
                      <img src="assets/img/sz1.png" alt=""/>
                   </div> 
                   <div className="apbc-service-content">
                     <div className="apbc-headline">
                       <a href="#"><h5>Agente/Revendedor</h5></a>
                     </div> 
                   </div>
                 </div> 
                 <div className="apbc-service-single">
                   <div className="apbc-img-wrapper">
                      <img src="assets/img/sz1.png" alt=""/>
                   </div> 
                   <div className="apbc-service-content">
                     <div className="apbc-headline">
                       <a href="#"><h5>Documentos</h5></a>
                     </div> 
                   </div>
                 </div> 
                 <div className="apbc-service-single">
                   <div className="apbc-img-wrapper">
                      <img src="assets/img/sz1.png" alt=""/>
                   </div> 
                   <div className="apbc-service-content">
                     <div className="apbc-headline">
                       <a href="#"><h5>Suporte</h5></a>
                     </div> 
                   </div>
                 </div> 
                 
                  
 
                </div> 
                <br /><br />
               </div>
             </div>
           </section>  
         </Container>
    </div>
  )
}

const Container = styled.div`

.apbc-service-slider{
    display:flex; 
    justify-content:space-between;

    
    .apbc-service-single{ 
      padding:20px;
      width:410px;   
      background:#ffff;

         .apbc-img-wrapper{
             min-height:200px;
         } 
     } 
  }
`;

export default AgentRequirementsSection
