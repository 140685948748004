import React from 'react'
import { styled } from 'styled-components'

function Title({text1, color, text2}) {
  return (
     <TitleContainer>
           <h1 className={color === 0 ? 'text-white' : 'text-dark'}>{text1} <span>{text2} </span> </h1>
     </TitleContainer>
  )
}


const TitleContainer = styled.div`
    max-width:600px;

    h1{
      font-size:60px;
      font-weight:600;

      span{
         color:var(--red);
      }
    }

    
     
`;

export default Title
