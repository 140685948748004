import React from 'react'
import SocialLinks from './SocialLinks';
import Img from '../../Assets/images/shape.png' 
import styled from 'styled-components';

function TermsHeader() {
    return (
        <Container style={{backgroundImage:`url('${Img}')`}}>
        <div className="wrapper">
              <div className="text-center ">
                   <div className="block-text text-center  animate__animated animate__fadeInLeft"> 
                        <h1 className='large-title'>Termos e condições</h1>
                        <p>
                        Leia nossos termos abaixo para saber mais sobre seus direitos e responsabilidades como usuário da Movicel.
                        </p>
                   </div>
              </div>
        </div> 
        <SocialLinks/>
    </Container>
      )
    }
    
    
    
    const Container = styled.div`
        width:100%;
        min-height:408px;
        padding-top:200px;
        background-color:var(--bluedark);
        margin-bottom:20px; 
        background-position: center;
        background-repeat: no-repeat;
        background-size:cover;
        position:relative;

        .text-center{
            display:flex;
            align-items:center;
            justify-content:center;
            flex-direction:column;
        }
    
        .space{
            display:flex;
            align-items:center;
    
              img{
                 width:100%;
                 min-width:200px;  
                 max-width:555px;
              }
        }
     
       
    
        .block-text{  
              .large-title{
                  max-width:700px;
              }
         
              p{
                max-width:650px;
                color:var(--white);
              }
        }
    
     
    
    `;

export default TermsHeader
