import React from 'react'
import Slider from 'react-slick';
import {FaArrowRight}  from "react-icons/fa";

function ServicesSlider() {
    
        const settings = {
          dots: true,
          infinite: true,
          speed: 500,
          slidesToShow:window.innerWidth > 900 ? 3 : 1,
          slidesToScroll:window.innerWidth > 900 ? 3 : 1
        };
        return (
          <div> 
             <section className="apbc-service-section" id="mov_planos">
             <div className="container">
               <div className="row">
                 <div className="col-lg-6">
                    <br />
                   <div className="apbc-title-area wow fadeInUp">
                     <span className="apbc-subtitle">Planos</span>
                     <div className="apbc-headline">
                       <h3>Onde Falar e Navegar Kuia Mais!</h3>
                     </div>
                   </div>
                 </div>
                 <div className="col-lg-6 align-self-end">
                   <div className="apbc-secondary-btn wow fadeInUp">
                     <a href="#" className='bg-bl'>Mais Planos<div className='icon'><FaArrowRight/></div></a>
                   </div>
                 </div>
               </div>
               <div >
               <Slider className="apbc-service-slider" {...settings}>
            <div className="apbc-service-single">
                   <div className="apbc-img-wrapper">
                     <img src="assets/img/business-4/card.png" alt=""/>
                   </div>
                   <div className="apbc-icon-wrapper">
                     <i className="flaticon-bank-1"></i>
                   </div>
                   <div className="apbc-service-content">
                     <div className="apbc-headline">
                       <a href="#"><h5>Kargas</h5></a>
                     </div>
                     <div className="apbc-pera-txt">
                       <p>Agora o Plano KARGA NICE dá o DOBRO DOS DADOS: 2GB, 300 minutos para Movicel, 50 minutos + 50 SMS para outras redes.</p>
                     </div>
                     <div className="apbc-readmore-btn">
                       <a href="#" className='d-flex'>Saiba mais <div className='icon'><FaArrowRight/></div></a>
                     </div>
                   </div>
                 </div> 

                 <div className="apbc-service-single">
                   <div className="apbc-img-wrapper">
                     <img src="assets/img/business-4/card.png" alt=""/>
                   </div>
                   <div className="apbc-icon-wrapper">
                     <i className="flaticon-bank-1"></i>
                   </div>
                   <div className="apbc-service-content">
                     <div className="apbc-headline">
                       <a href="#"><h5>Movinet</h5></a>
                     </div>
                     <div className="apbc-pera-txt">
                       <p>Agora o Plano KARGA TUDO dá o DOBRO DOS DADOS a um preço mais baixo: 4GB, 500 minutos/SMS para Movicel, 60 minutos + 60 SMS para outras redes.</p>
                     </div>
                     <div className="apbc-readmore-btn">
                       <a href="#" className='d-flex'>Saiba mais <div className='icon'><FaArrowRight/></div></a>
                     </div>
                   </div>
                 </div> 

                 <div className="apbc-service-single">
                   <div className="apbc-img-wrapper">
                     <img src="assets/img/business-4/card.png" alt=""/>
                   </div>
                   <div className="apbc-icon-wrapper">
                     <i className="flaticon-bank-1"></i>
                   </div>
                   <div className="apbc-service-content">
                     <div className="apbc-headline">
                       <a href="#"><h5>Kuias</h5></a>
                     </div>
                     <div className="apbc-pera-txt">
                       <p>SURPRESA!! Agora o Plano KARGA SUPER dá o DOBRO DOS DADOS: 8GB,500 minutos/SMS para Movicel, 60 minutos + 60 SMS para outras redes.</p>
                     </div>
                     <div className="apbc-readmore-btn">
                       <a href="#" className='d-flex'>Saiba mais <div className='icon'><FaArrowRight/></div></a>
                     </div>
                   </div>
                 </div> 

                 <div className="apbc-service-single">
                   <div className="apbc-img-wrapper">
                     <img src="assets/img/business-4/card.png" alt=""/>
                   </div>
                   <div className="apbc-icon-wrapper">
                     <i className="flaticon-bank-1"></i>
                   </div>
                   <div className="apbc-service-content">
                     <div className="apbc-headline">
                       <a href="#"><h5>Spyka</h5></a>
                     </div>
                     <div className="apbc-pera-txt">
                       <p>Agora o Plano KARGA BWÉ dá o DOBRO DOS DADOS a um preço mais baixo: 16GB, 1000 minutos/SMS para Movicel, 80 minutos + 100 SMS para outras redes e 5 minutos Internacionais.</p>
                     </div>
                     <div className="apbc-readmore-btn">
                       <a href="#" className='d-flex'>Saiba mais <div className='icon'><FaArrowRight/></div></a>
                     </div>
                   </div>
                 </div>
                 
                 <div className="apbc-service-single">
                   <div className="apbc-img-wrapper">
                     <img src="assets/img/business-4/card.png" alt=""/>
                   </div>
                   <div className="apbc-icon-wrapper">
                     <i className="flaticon-bank-1"></i>
                   </div>
                   <div className="apbc-service-content">
                     <div className="apbc-headline">
                       <a href="#"><h5>Aditivos</h5></a>
                     </div>
                     <div className="apbc-pera-txt">
                       <p>Agora o Plano KARGA VIP dá o DOBRO DOS DADOS: 24GB, 1500 minutos/SMS para Movicel, 150 minutos+300 SMS para outras redes e 10 minutos Internacionais.</p>
                     </div>
                     <div className="apbc-readmore-btn">
                       <a href="#" className='d-flex'>Saiba mais <div className='icon'><FaArrowRight/></div></a>
                     </div>
                   </div>
                 </div>
               </Slider>
               </div>
             </div>
           </section>  
          </div>
        );
}

export default ServicesSlider
