import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { styled } from 'styled-components' 
import { FaUserAlt } from "react-icons/fa";
import Button from 'react-bootstrap/Button'; 
import { Offcanvas } from 'react-bootstrap';
import { Search } from '@mui/icons-material';
import { IoMenu } from "react-icons/io5";
import { RiUser3Fill } from "react-icons/ri";


const Logo = [
   require('../../Assets/images/logo.png'),
   require('../../Assets/images/logo1.png'), 
]

function MvNavbar(props) { 
   const [show, setShow] = useState(false); 
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
 
  return (
     <Container>
        <header className={props.black ? 'apbc-header-section bg-blue' : 'apbc-header-section'} >
               <div className="container">
                 <div className="row align-items-center">
                   <div className="col-lg-3 col-md-6 col-7">
                     <div className="apbc-logo-wrapper">
                       <Link  to="/" ><img src={props.purple ? Logo[1] : Logo[0]} alt="Movicel"/></Link>
                     </div>
                   </div>
                   <div className="col-lg-8 apbc-desktop-menu">
                     <div className="apbc-nav-menu">
                       <ul className='menu-list'>
                         <li className='menu-container'>
                            <Link className={(props.active && props.active === 1) ? "nm nav-link active" : "nm nav-link"} to="/">Particular <div className="plus">+</div></Link>
                               <div className="dropdown-menu">
                                  <ol>
                                    <li><Link className="nav-link" to="#">Tarifarios</Link></li>
                                    <li><Link className="nav-link"to={!props.purple ? "/service_plans" : "#"}>Planos</Link></li>
                                    <li><Link className="nav-link" to="/services">Serviços</Link></li>
                                    <li><Link className="nav-link" to="/store">Telefones</Link></li> 
                                  </ol>
                               </div>
                         </li>   
                         <li className='menu-container'>
                            <Link className={(props.active && props.active === 2) ? "nm nav-link active" : "nm nav-link"} to="/companies">Empresas <div className="plus">+</div> </Link>
                               <div className="dropdown-menu">
                                  <ol>
                                    <li><Link className="nav-link" to="#">Tarifarios</Link></li>
                                    <li><Link className="nav-link"to={!props.purple ? "/service_plans" : "#"}>Planos</Link></li>
                                    <li><Link className="nav-link" to="/services">Serviços</Link></li>
                                    <li><Link className="nav-link" to="/store">Telefones</Link></li> 
                                    <li><Link className="nav-link" to="#">Apoio ao cliente</Link></li> 
                                  </ol>
                               </div>
                         </li>  
                         <li className='menu-container'>
                            <Link className={(props.active && props.active === 3) ? "nm nav-link active" : "nm nav-link"} to="/agents">Agentes <div className="plus">+</div></Link>
                               <div className="dropdown-menu">
                                  <ol>
                                    <li><Link className="nav-link" to="/agents">Como ser agente</Link></li> 
                                  </ol>
                               </div>
                          </li>   
                       </ul>
                     </div>
                   </div>
                   <div className="col-lg-1 col-md-6 col-5">
                  <div className="d-flex" style={{alignItems:"center"}}>
                     <li className='mr-2'><Link className="nav-link" to="#"><Search/></Link></li> 
                     <div className="apbc-sidebar-btn"  onClick={handleShow}>
                         <Link  to="#" > <RiUser3Fill  /></Link>
                     </div>
                     <div className="apbc-mobile-menu-open"  onClick={handleShow}>
                       <Link  to="#" > <RiUser3Fill  /></Link>
                     </div>
                    <div className="menu-mr">
                     <div className="menu-button">
                       <IoMenu />
                      </div>
                    </div>
                  </div>
                   </div>
                 </div>
               </div>
             </header> 
           
             <div className="apbc-mobile-navigation">
               <div className="apbc-mobile-menu-wrapper">
                 <Link  to="#"  className="apbc-mobile-menu-close"><i className="fas fa-times"></i></Link>
                 <nav>
                   <ul>
                     <li><Link className={(props.active && props.active=== 1) ? "nav-link active" : "nav-link"} to="/">Home</Link></li> 
                     <li><Link className="nav-link" to={!props.purple ? "/service_plans" : "#"}>Planos</Link></li>
                     <li><Link className="nav-link" to="/store">Telefones</Link></li>
                     <li><Link className="nav-link" to="/offers">Ofertas</Link></li>
                     <li><Link className="nav-link" to="/about">Porquê Movicel</Link></li>
                     <li><Link className="nav-link" to="/companies">Empresas</Link></li>
                   </ul>
                 </nav>
               </div>
             </div>  
      <Offcanvas show={show} onHide={handleClose} placement='end'>
        <div className={props.purple ? "cover purple" : "cover"}>

        </div>
      <div className="canvas-body">
      <Offcanvas.Header >
          <div className="space">
          <Offcanvas.Title></Offcanvas.Title>
          <div className="close btn bg-white" onClick={handleClose}>x</div>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body> 
               <div className="apbc_sidebar_info_content"> 
                 <div className="center-data">
                 <div className="apbc_sidebar_logo">
                   <Link  to="#" ><img src={props.purple ? "assets/img/business-4/login-logo-purple.png" : "assets/img/business-4/login-logo-red.png"} alt=""/></Link>
                 </div> 
                 </div>
               <div className={props.purple ? "card-box purple" : "card-box"} > 
               <div className="apbc-pera-txt">
                   <span><p className={props.purple ? "purple" : "red"} > My Movicel</p></span>
                 </div>
                 <div className="apbc-sidebar-gallery">
                   <form action="#" method="post">
                           <div className="wrapper">
                             <div className="center">
                               <div className="signin-area-box">
                                   <div className="content">
                                       <div className="signin-client-dets">
                                             <p className="mb-2">
                                               <span>Introduza os seus dados de acesso nos campos abaixo.</span>
                                             </p>
                                             <div className="input-box mt-3">
                                                 <label for="mvc-phone">Numero da Movicel *</label>
                                                 <input type="tel"  id="mvc-phone" maxlength="9" minlength="9" required className="form-control" placeholder="ex: 999000444"/>
                                             </div>
                                             <div className="input-box mt-3">
                                               <label for="mvc-password">Senha *</label>
                                               <input type="password" id="mvc-password" required  maxlength="30"  minlength="6" className="form-control" placeholder="insira palavra-passe aqui"/>
                                           </div>
                                           <Link to="/mymovicel"><button type="submit" className="btn btn-b text-light"><span>Entrar</span></button></Link>
                                           <div className="col-box mt-2 mb-2"><Link to="">Esqueceu a sua senha?</Link></div>
                                           <div className="d-flex">
                                               <p>Ainda não tem uma conta?</p><span style={{color:`${!props.purple ? "var(--red)" : "var(--purple)"}`}}><Link  to="#"  className=" ml-2 drop-box ml-1"> Crie aqui</Link></span>
                                           </div>
                                       </div> 
                                   </div>
                               </div>
                             </div>
                           </div>
                  </form>
                 </div> 
                </div>  
               </div>  
        </Offcanvas.Body>
      </div>
      </Offcanvas> 
     </Container>
  )
}



const Container = styled.div`
.apbc-header-section{
  a{
    color:#ffff;

    &:hover{
      color:#ffff !important;
    }
}



.menu-button{
    background:#ffff;
    color:var(--bluedark);
    cursor:pointer;

     svg{
        color:var(--bluedark);
        fill:var(--bluedark);
     }
}



}

.apbc-desktop-menu{
   padding:0px;   

   .menu-list{
     padding:0px;
     margin:0px;
   }
}

.menu-container{
    position:relative; 
    
    .nm{
      display:flex;
      align-items:center;  
      padding-left:15px !important; 
      margin-right:20px;

       .plus{
          margin-left:5px;
          transition:all 1s ease-in-out;
       }
  }

  .dropdown-menu{
      position:absolute;
      min-width:240px;
      top:83px;
      z-index:-1000;
      opacity:-1;
      transition:all 1s ease-in-out;
      background:var(--bluedark);
      left:0px;
      min-height:50px;
      display:block;
      border-radius:0px;
      border:none;
      border-top:4px solid var(--red);
      visibility: hidden;

        ol {
           padding:0px;
           display:flex;
           flex-direction: column;

             li{
                margin:0px;
                padding:0px; 

                 a{  
                    padding:0px;
                    padding-left:15px;
                    padding-bottom:20px;
                 }
             }
        } 
    }
 

    &:hover{

      .plus{
        transform: rotate(90deg);
      }

      .dropdown-menu{ 
         opacity:1;
         z-index:1000;
         visibility: visible;
     }
  }



}


.bg-blue{
    a{
        color:#ffff;

        &:hover{
          color:#ffff !important;
        }
    }

    svg{
      fill:#ffff;
    }
}

 .apbc-mobile-menu-open{  
    svg{
      min-width:35px;
      min-height:35px;
      fill:var(--white);
    }

    &:hover{
       background:transparent !important; 
       color:red;

         svg{
            fill:var(--red) !important;
            color:var(--red) !important;
         } 
    }
 
  }

`;

const Containers = styled.div`
    position:absolute;
    top:0px;
    left:0px;
    z-index:1000;
    width:100%;
    border-bottom:1px solid rgba(194, 194, 194, 0.3);

   .menu{
        display:flex;
        align-items:center;
        justify-content:space-between;
        padding:7px 10px;

        .d-flex{
            img{
                  max-width:150px;
            }
        }


         ul{
            padding:0px;
            margin:0px;

            li{
                margin: 0px 25px;
                a{
                   text-decoration:unset;
                   color:var(--white);
                   position:relative;
                   font-weight:599;
                }
             }
         }


         .btn{
              width:50px;
              height:50px;
              border:100%;
              display:flex;
              align-items:center;
              justify-content:center;
              background:var(--red);
              border:2px solid var(--red) !important;
              border-radius:100%;
              border:none;
              margin:0px;

              &:hover{
                 background:transparent !important; 
                   svg{
                      fill:var(--red) !important;
                   } 
              }

              svg{
                  min-width:35px;
                  min-height:35px;
                  fill:var(--white);
              }
         }
   }



     .menu.dark-links{
           a{color:var(--dark);}
           .btn{
               background:var(--red);
                  svg{
                      fill:var(--white);
                  }
           }
     }
`;

export default MvNavbar
