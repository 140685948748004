import React from 'react'
import MvNavbar from './Components/MvNavbar'
import AboutHeader from './Components/AboutHeader'
import { styled } from 'styled-components'
import ScrollTop from './Components/ScrollTop';
import Footer from './Components/Footer';
import AgentsHeader from './Components/AgentsHeader';
import AgentRequirementsSection2 from './Components/AgentRequirementsSection2';
import AgentsAbout from './Components/AgentsAbout';
import AgentsForm from './Components/AgentsForm';

function AgentsPage() {
  document.title = "Agentes";

  return (
    <Container>
    <MvNavbar active={3} />
      <AgentsHeader />
      <AgentsAbout />
      <AgentRequirementsSection2/>
      <br />
      <AgentsForm/>
    <Footer />
    <ScrollTop />
  </Container>
  )
}

const Container = styled.div`
.apbc-about-section .apbc-img-wrapper{
    min-height:600px;  
    background:#E7E9EF;
  
      img{
         width:100%;
      }
  }
  
`;

export default AgentsPage
