import React from 'react'
import { AiOutlineMessage } from "react-icons/ai";
import { BsFillXDiamondFill, BsGlobeAsiaAustralia, BsDatabase } from "react-icons/bs";
import { styled } from 'styled-components';


function CompanyAdditions() {
    const Data = [
        {
          title:'Aditivos internacionais',
          icon:<BsGlobeAsiaAustralia/>,
          description:'lorem psum olorsit amet ectetur adipiscing elit, sed dov.'
         },
         {
           title:'Aditivos SMS',
            icon:<AiOutlineMessage/>,
            description:'lorem psum olorsit amet ectetur adipiscing elit, sed dov.'
         },
         {
            title:'Aditivos dados',
            icon:<BsDatabase/>,
            description:'lorem psum olorsit amet ectetur adipiscing elit, sed dov.'
        }, 
        {
            title:'Mais+',
            icon:<BsFillXDiamondFill/>,
            description:'lorem psum olorsit amet ectetur adipiscing elit, sed dov.'
        },
    ];


  return (
     <Container>
         <div className="image">
              <img src="assets/img/chat.png" alt="" />
         </div>
         <div className="block"> 
              <div className="apbc-title-area  wow fadeInUp"> 
                <div className="apbc-headline">
                <h3>Complemente o seu plano com os Aditivos Empresa</h3>
                </div>
            </div>
              <div className="items-row">
                   {Data.map((item)=>(
                        <div className='item'>
                           <div className="icon">
                              {item.icon}
                           </div>
                           <div className="dets">
                               <h2>{item.title}</h2>
                               <p>{item.description}</p>
                           </div>
                        </div>
                   ))}
              </div>
         </div> 
     </Container>
  )
}


const Container = styled.div`
   display:flex;
   align-items:center;

   img{
      min-width:600px;
   }

   .items-row{
       display:flex;
       flex-wrap:wrap;
       justify-content:space-between;
       margin-top:30px;

        .item{
              width:48%; 
              margin:15px 0px;  
              display:flex;

              h2{
                  font-size:18px;
                  color:var(--bluedark);
              }


              .dets{
                  padding-left:15px;

                   p{
                      font-size:15px;
                   }
              }

               .icon{
                   background: linear-gradient(to top, var(--purple) 0%, var(--bluedark) 100%);
                   width:70px;
                   min-width:70px;
                   height:70px;
                   margin-bottom:10px;
                   display: flex;
                   align-items: center;
                   justify-content: center;
                   border-radius:100%;

                   svg{
                       width:35px;
                       height:35px
                       fill:var(--white);
                       color:var(--white);
                   }
               }


        }
   }

`;

export default CompanyAdditions
