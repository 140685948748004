import React from 'react'
import MvNavbar from './Components/MvNavbar'
import ContactHeader from './Components/ContactHeader'
import { styled } from 'styled-components'
import Footer from './Components/Footer'
import ContactQuestions from './Components/ContactQuestions'
import ScrollTop from './Components/ScrollTop'

function ContactPage() {
  document.title = "Contatos"
  return (
     <Container>
         <MvNavbar />
         <ContactHeader /> 
         <br /><br /> <br />
         <ContactQuestions/>
         <div className="experience bg-primary">
           <div className="wrapper">
            <div className="space">
                <h1 className='mediumn-title'>Pronto para uma nova experiencia de comunicação ?</h1>
                <div>
                    <button className="btn btn-circle bg-light">Entrar agora</button>
                </div>
             </div>
           </div>
         </div>
         <Footer/>
         <ScrollTop />
     </Container>
  )
}


const Container = styled.div`
    display:block;

    
    .space{
        display:flex;
        align-items:center;
        justify-content:space-between;
        padding:20px;


          .mediumn-title{ 
               color:var(--white);
           }

           button{
              padding:10px 20px;
              color:var(--red);
           }
   }
`;

export default ContactPage
