import { Avatar } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import { styled } from 'styled-components'
import {AiOutlineLogout,AiOutlineSetting} from "react-icons/ai";
import { BsTelephone} from "react-icons/bs";


const Logo = [
  require('../../Assets/images/logo.png'),
  require('../../Assets/images/logo1.png'), 
]

function PtHeader(props) {
  return (
     <Container>
        <div className="wrapper">
        <div className="space">
             <div className="d-flex">
               <Link  to="/" ><img src={props.purple ? Logo[1] : Logo[0]} alt="Movicel"/></Link>
             </div>
             <div className="d-flex">
                  <Link to="#"><AiOutlineSetting/> Configurações</Link>
                  <div className="ml-2">
                      <Link  to="#" className='ml-2 icon' style={{color:"var(--red)"}} ><AiOutlineLogout/></Link>
                  </div>
             </div>
         </div>
         <div className="d-flex user-block">
             <Avatar src="" sx={{width:140,height:140,bgcolor:"var(--red)"}} alt="MC"></Avatar>
             <div className="block">
                 <h1 className="name">Manuel Pedro Carlos</h1>
                 <div className="number">
                     <div className="icon bg-primary"><BsTelephone/></div>
                     <span>999 000 000</span>
                 </div>
             </div>
         </div>
        </div>
     </Container>
  )
}


const Container = styled.div`
    width:100%;
    position:absolute;
    top:0px;
    left:0px;
    padding:20px 0px;



      .MuiAvatar-root{
          border:4px solid var(--white);
          font-size:40px;
          margin-right:20px;
      }

      .space{
          align-items:center;

         a img{
             max-height: 80px;
          }
           
           .d-flex {
            align-items:center;
            
            .ml-2{
              margin-left:20px;
            }
  
            a{
                color:var(--white);
                font-size:18px;

                  svg{
                      width:25px;
                      height:25px;
                  }
            }

            .icon{
                background:var(--white);
                width:40px;
                height:40px;
                border-radius:100%;
                display:flex;
                align-items:center;
                justify-content:center;
            }
          }
      }


      .user-block{
          margin-top:40px;
          align-items:center;

            .name{
                color:var(--white);
                font-size:25px;
                margin:0px;
            }

            .number{
                display:flex;
                background:var(--white);
                overflow:hidden;
                border-radius:7px;
                width:max-content; 
                margin-top:10px;

                span{
                    padding:6px 15px;
                    color:var(--bluedark);
                }

                .icon{
                    width:40px;
                    display:flex;
                    align-items:center;
                    justify-content:center;

                     svg{
                        color:var(--white);
                        fill:var(--white);
                     }
                }
            }
      }


     
`;


export default PtHeader
