import React, { useState } from 'react'
import { styled } from 'styled-components'  
import Img from '../../Assets/images/shape.png' 
import { FaArrowRight } from "react-icons/fa";
import PtHeader from '../Components/PtHeader';
import {FcSimCardChip}  from "react-icons/fc";
import PtDashboard from '../Components/PtDashboard';
import Ptconsumption from '../Components/Ptconsumption';
import PtMyArea from '../Components/PtMyArea';
const CTIMG =   require('../../Assets/images/ct.png');

function PortalMain() {

  const [ActiiveTab, setActiveTab] = useState(1);


  return (
     <div>
      <Container style={{backgroundImage:`url('${Img}')`}}>
      <div className="wrapper">
            <PtHeader />
            <div className="tabs">
                <div className="space">
                    <div className="d-flex">
                          <ul>
                             <li onClick={()=>setActiveTab(1)} className={ActiiveTab ===  1 ? 'active' : ""}>Resumo</li>
                             <li onClick={()=>setActiveTab(2)} className={ActiiveTab ===  2 ? 'active' : ""}>Consumos</li>
                             <li onClick={()=>setActiveTab(3)} className={ActiiveTab ===  3 ? 'active' : ""}>Carregamentos</li>
                             <li onClick={()=>setActiveTab(4)} className={ActiiveTab ===  4 ? 'active' : ""}>Tarifarios</li>
                             <li onClick={()=>setActiveTab(5)} className={ActiiveTab ===  5 ? 'active' : ""}>Serviços</li>
                             <li onClick={()=>setActiveTab(6)} className={ActiiveTab ===  6 ? 'active' : ""}>Minha área</li>
                          </ul>
                    </div>
                    <div className="d-flex">
                        <div className="simcard-status">
                            <FcSimCardChip />
                            <div className="block">
                                <span>Estado do cartão SIM</span>
                                <div className="d-flex">
                                    <div className="dot bg-info"></div>
                                    <div className="ml-2"><small>Activo</small></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>  
     </Container>
      <Content>
           <div className="wrapper">
             <div className={ActiiveTab ===  1 ? 'tab-item active' : "tab-item"}>
                <PtDashboard />
             </div>
             <div className={ActiiveTab ===  2 ? 'tab-item active' : "tab-item"}>
                <Ptconsumption />
             </div>
             <div className={ActiiveTab ===  6 ? 'tab-item active' : "tab-item"}>
                <PtMyArea/>
             </div>

           </div>
      </Content>
     </div>
  )
}

const Content = styled.div` 
    width:100%;
    padding-top:50px; 
    display:block;

      .tab-item{
         display:none;
      }

      .tab-item.active{
          display:block;
      }

      label{
          color:var(--bluedark);
      }
`;


const Container = styled.div`
    width:100%;
    height:400px;
    padding-top:200px;
    background-color:var(--bluedark);
    margin-bottom:20px; 
    background-position: center;
    background-repeat: no-repeat;
    background-size:cover; 

 

      .tabs{
          width:100%;
          padding:10px 20px; 
          margin-top:155px;
          height:90px;
          border-radius:6px;
          background:var(--white);
          box-shadow:0px 3px 10px rgba(0,0,0,0.2);


            ul{
                 padding:0px;
                 display:flex;
                 margin:0px;
                 align-items:center;

                 li{
                    font-size:18px;
                    margin:0p;
                    margin-right:20px;
                    cursor:poiter;
                    color:var(--bluedark);
                    cursor:pointer;

                    &:hover{
                        color:var(--red);
                    }
                 }

                 li.active{
                    padding:10px 20px;
                    background:var(--bluedark);
                    color:var(--white);
                    border-radius:6px;

                    &:hover{
                      color:var(--white);
                  }
                 }
            }

            .simcard-status{
                display:flex;
                align-items:center;
                color:var(--bluedark);

                svg{
                    width:65px;
                    height:65px;
                    margin-right:10px;
                } 

                .d-flex{
                    align-items:center;
                }
                

                .dot{
                  width:10px;
                  height:10px;
                  border-radius:100%;
              }
            }


      }
 

`;

export default PortalMain
