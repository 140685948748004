import React from 'react'
import { styled } from 'styled-components'
import Cover from '../../Assets/images/shape.png';
import Title from './Title';
import SocialLinks from './SocialLinks';
import Img from '../../Assets/images/shape.png'
import AboutImg from '../../Assets/images/service.svg'
import { FaArrowRight } from "react-icons/fa";



function ServiceHeader() {
    return (
        <Container style={{backgroundImage:`url('${Img}')`}}>
        <div className="wrapper">
              <div className="space">
                   <div className="block-text animate__animated animate__fadeInLeft"> 
                        <h1 className='large-title'>Os serviços que te mantém sempre activo, ligado na rede</h1>
                        <p>
                          Dolor repudiandae molestias autem praesentium est adipisci reprehenderit 
                          hic provident. Temporibus voluptatibus eligendi id ad dolorum deserunt ex 
                          quia sapiente culpa, quae tenetur! Enim eos, et saepe reprehenderit unde
                           similique quia atque necessitatibus.
                        </p>
                   </div>
                   <div className="imageBox animate__animated animate__fadeInRight">
                      <img src={AboutImg} alt="" />
                   </div>
              </div>
        </div> 
        <SocialLinks/>
    </Container>
      )
    }
    
    
    const Container = styled.div`
        width:100%;
        min-height:708px;
        padding-top:200px;
        background-color:var(--bluedark);
        margin-bottom:20px; 
        background-position: center;
        background-repeat: no-repeat;
        background-size:cover;
        position:relative;
    
        .space{
            display:flex;
            align-items:center;
            
            .imageBox{ 
               padding-left:20px;
    
               img{
                    width:100%;
                    min-width:200px;  
                    max-width:500px;
                }
            } 
    
            
        }
     
       
    
        .block-text{   
              .large-title{
                  max-width:700px;
              }
         
              p{
                max-width:650px;
                color:var(--white);
              }
        }
    
     
    
    `;

export default ServiceHeader


 